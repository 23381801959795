import React, { useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js';
import ApiProvider, { ApiState } from '../../../../Context/ApiProvider';
import axios from 'axios';
import { Slide, toast } from 'react-toastify';
import useAxios from '../../../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

const CreateNewUser = ({setShowModal}) => {
  const {user, setSubUser, token} = ApiState()
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('')
  const [subUsername, setSubUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading,setLoading] = useState('')
  const [errors, setErrors] = useState({});
  let validationErrors = {};

  const navigate = useNavigate()
  const axiosInstance = useAxios()

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  console.log(user);
  
 
  const createUser = async () => {
    if(!email || !password || !subUsername){
      if (!subUsername) validationErrors.subUsername = "User Name is required.";
      if (!password) validationErrors.password = "Password is required.";
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        console.log(errors);
        toast.error('Please fill in all fields.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          });
        return;
      }
    }
      try {
       console.log('datafor sending')

        await axiosInstance.post(`${process.env.REACT_APP_SERVER_URL}/subusers`,{
        	 subuser_name: subUsername,
          password: password
        },{
          headers:{
            authorization: `Bearer ${token}`
          }
        }).then((response)=>{
          console.log(response)       
          console.log("inside response", response);
          setShowModal(false)
        })
      } catch (error) {
        console.log(error);
        if(error.status === 409){
          toast.warning("user is already registered.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
            });
        }
      }
  }
  return (
    <div className='flex justify-center items-center w-full'>
      <div className='py-14 px-[7%] bg-white border border-slate-50 w-fit rounded-2xl'>
        <div className='max-w-[550px] w-full flex justify-center items-center flex-col'>
            <div className='pb-6 text-center'>
                <h1 className='text-4xl font-bold pb-6'>Create New User</h1>
                <p className=' text-slate-500 max-w-[450px]'>You can invite users via email or send a magic link to let them join your team instantly.</p>
            </div>
            <div className='w-full'>
            <div className='pb-4'>
            <p className='font-semibold text-sm pb-1'>User Name <span className='text-red-500 text-base'>*</span></p>
            <input value={subUsername} onChange={(e) => setSubUsername(e.target.value)} className='border border-slate-300 p-3 w-full rounded-lg' type="text" placeholder='John Doe' />
            </div>
        <div className='pb-6'>
          <p className='font-semibold text-sm pb-1'>Set Password <span className='text-red-500 text-base'>*</span></p>
          <div className='relative'>
            <input
              value={password}
              onChange={((e) => setPassword(e.target.value))}
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter your password'
              className='border border-slate-300 p-3 w-full rounded-lg'
            />
            <button
              type='button'
              onClick={handleTogglePassword}
              className='absolute inset-y-0 right-0 flex items-center pr-3'
            >
              <Icon icon={showPassword ? 'mage:eye-off' : 'ph:eye-light'} width="24" height="24" style={{ color: '#294DD1' }} />
            </button>
          </div>
        </div>

        <button onClick={createUser} className='w-full bg-black text-white py-3 rounded-full font-semibold mt-2'>Create User</button>
        </div>

        </div>
      </div>
    </div>
  )
}

export default CreateNewUser
