import React, { useContext } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import './settingsidebar.css'
import { SettingContext } from '../SettingContext/SettingContext'
import { useNavigate } from 'react-router-dom'

function SettingSideBar({activeState}) {

  // const {activeState,setActiveState} = useContext(SettingContext)

  const navigate = useNavigate()

  function toProfile () {
    navigate('/setting/profilesetting')
  }

  function toSecurity () {
    navigate('/setting/security')
  }

  function toPayInfo () {
    navigate("/setting/payinfo")
  }

  function toDashboard () {
    navigate("/dashboard")
  }

  function toSubUser () {
    navigate("/setting/add-sub-user")
  }
 
  function activeButton (value) {
    // setActiveState(value)
  }


  return (
    <div className='settin-sidebar-container'>
      <div className='setting-sidebar-backbtn' onClick={toDashboard}>
        <button><Icon icon="material-symbols-light:arrow-back-ios" /></button>
      </div>
      <div className='setting-buttons-container'>
        <div className={activeState === 'profile' ? 'setting-button-active' : 'setting-button-notactive' } onClick={toProfile}>
            <button onClick={()=>activeButton('profile')}><Icon icon="iconamoon:profile-light" width="24" height="24" /> Profile</button>
        </div>
        <div className={activeState === 'security' ? 'setting-button-active' : 'setting-button-notactive' } onClick={toSecurity}>
            <button onClick={()=>activeButton('security')}><Icon icon="gg:lock" width="24" height="24" /> Security</button>
        </div>
        <div className={activeState === 'payinfo' ? 'setting-button-active' : 'setting-button-notactive' } onClick={toPayInfo}>
            <button onClick={()=>activeButton('payinfo')}><Icon icon="fluent:payment-16-regular" width="24" height="24"  /> Pay Info</button>
        </div>
        <div className={activeState === 'sub-user' ? 'setting-button-active' : 'setting-button-notactive' } onClick={toSubUser}>
            <button onClick={()=>activeButton('sub-user')}><Icon icon="mdi:account-cog" width="24" height="24" /> Sub User</button>
        </div>
      </div>
    </div>
  )
}

export default SettingSideBar
