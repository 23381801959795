import React, { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import VerificationCommon from "../Common Components/Verification Common/VerificationCommon";
import { json, useNavigate } from "react-router-dom";
import { ApiState } from "../../../Context/ApiProvider";
import axios from "axios";
import { Slide, toast } from "react-toastify";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({});
  const {user,setUser, token,setToken,setRefreshToken,refreshToken} = ApiState()
  const signupBtn = 'signup'

  let validationErrors = {};
  
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate(0)

  async function toDashboard () {
    if(!email || !password){
      if (!email) validationErrors.email = "Email is required.";
      if (!password) validationErrors.password = "Password is required.";
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        console.log(errors);
        toast.error('Please fill in all fields.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          });
        return;
      }
    }
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/login`,{
        email,
        password
      }).then((response)=>{
        console.log(response);
        toast.success('Success', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          });
        localStorage.setItem("token",JSON.stringify( response.data.token.access_token));
      setToken(response.data.token.access_token)
      localStorage.setItem('refreshToken',JSON.stringify(response.data.token.refresh_token)) 
      setRefreshToken(response.data.token.refresh_token) 
      localStorage.setItem('user',JSON.stringify(response.data.user))    
      setUser(response.data.user)  
        navigate('/dashboard')
      })
    } catch (error) {
      console.log(error);
      if(error.status === 404){
        toast.error('User not found', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          });
      } 
      if(error.status === 401){
        toast.error('Password is incorrect', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          });
      } 
    }
  }
  function toSignUp () {
    navigate('/signup')
  }
  function toResetPassword () {
    navigate('/forgetpassword')
  }
  return (
    <>
    <VerificationCommon signupBtn={signupBtn}>
    <div className="h-full flex justify-center items-center">
      <div className="px-[5%] py-3 text-left max-w-[500px] w-full">
        <div className="pb-5">
          <h1 className="text-2xl font-bold">Login</h1>
        </div>
        <div className="pb-2">
          <label htmlFor="email" className="text-sm pb-1">
            Email <span className="text-red-500 text-base">*</span>
          </label>
          <div className='relative'>
          <input
            className={`${errors.email ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`}
            value={email}
            onChange={(e)=> setEmail(e.target.value)}
            type="text"
            id="email"
            placeholder="example@gmail.com"
          />
            <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
              {errors.email && (
                <Icon icon="fluent:info-24-regular" width="24" height="24"  style={{color: "red"}} />
              )}
            </div>
            </div>
        </div>
        <div className="pb-3">
          <label htmlFor="password" className="text-sm pb-1">
            Password <span className="text-red-500 text-base">*</span>
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e)=> setPassword(e.target.value)}
              id="password"
              placeholder="Enter your password"
              className={`${errors.password ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`}
            />
            <button
              type="button"
              onClick={handleTogglePassword}
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              <Icon
                icon={showPassword ? "iconamoon:eye-off-bold" : "lucide:eye"}
                width="24"
                height="24"
                style={{ color: "#294DD1" }}
              />
            </button>
          </div>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              className="w-4 h-4 cursor-pointer rounded-lg border border-slate-200"
            />
            <p className="text-sm">Remember</p>
          </div>
          <p className="font-semibold text-sm" onClick={toResetPassword}>Forgot password</p>
        </div>
        <button className="w-full bg-black text-white py-3 rounded-full text-sm" onClick={toDashboard}>
          Sign in
        </button>
        <p className="mt-2 text-sm text-slate-600 hidden lg:block">
          You don't have an account?{" "}
          <span className="text-black font-semibold" onClick={toSignUp}>Sign up</span>
        </p>
      </div>
    </div>
    </VerificationCommon>
    </>
  );
}

export default Login;
