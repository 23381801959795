import React, { useState } from 'react'
import SettingSideBar from '../../SettingSideBar/SettingSideBar'
import { Icon } from '@iconify/react/dist/iconify.js'
import './settingprofile.css'
import Navbar from '../../../Dashboard/CommonComponents/Navbar/Navbar'
import profile from './../../../Assets/Images/GZp-tcMSTqOJ-270CwHCIA.webp'

function SettingProfile() {

  const [personalinfoedit,setPersonalinfoedit] = useState(false)
  const [contactdetailsedit,setContactdetailsedit] = useState(false)
  const [addressedit,setAddressedit] = useState(false)

  const [personalDetails,setPersonalDetails] = useState({
    firstName: 'Muhammad',
    lastName: 'Ahmed',
    organization: 'Pak IT'
  })
  const [contactDetails,setContactDetails] = useState({
    email: 'email.example@sample.com',
    phoneNumber: '+9200000000'
  })
  const [address,setaddress] = useState({
    house: 'House # 00, Street # 0',
    city: 'Sample City',
    country: 'Sample Country',
    postalNumber: '#0000'
  })

  const [personalinfo, setPersonalinfo] = useState(personalDetails)

  const [contactinfo, setContactinfo] = useState(contactDetails)

  const [addressinfo, setAddressinfo] = useState(address)

  const handlePIChange = (e) => {
    const { name, value } = e.target;

    setPersonalinfo((prevData) => ({
      ...prevData,
      [name]: value,  // dynamically update the respective field
    }));
  };

  const handleCIChange = (e) => {
    const { name, value } = e.target;

    setContactinfo((prevData) => ({
      ...prevData,
      [name]: value,  // dynamically update the respective field
    }));
  };

  const handleAIChange = (e) => {
    const { name, value } = e.target;

    setAddressinfo((prevData) => ({
      ...prevData,
      [name]: value,  // dynamically update the respective field
    }));
  };

  function personalInfoSave () {
    setPersonalDetails(personalinfo)
    setPersonalinfoedit(false)
  }
  function contactDetailsSave () {
    setContactDetails(contactinfo)
    setContactdetailsedit(false)
  }
  function addressEditSave () {
    setaddress(addressinfo)
    setAddressedit(false)
  }

  function onCancel () {
    setAddressinfo(address);
    setContactinfo(contactDetails);
    setPersonalinfo(personalDetails);
    setAddressedit(false)
    setContactdetailsedit(false)
    setPersonalinfoedit(false)
  }

  function openEditor (value) {
    if(value === 'personal'){
      setPersonalinfoedit(true)
      setContactdetailsedit(false)
      setAddressedit(false)
    }
    else if(value === 'address'){
      setAddressedit(true)
      setContactdetailsedit(false)
      setPersonalinfoedit(false)
    }
    else if(value === 'contact'){
      setAddressedit(false)
      setContactdetailsedit(true)
      setPersonalinfoedit(false)

    }
  }

  return (
    <div>
      <div>
        <Navbar/>
      </div>
      <div className='settingprofile-body'>
        <div><SettingSideBar activeState={'profile'}/></div>
        <div className='setting-profile-form-outer'>
          <div className='setting-profile-form-inner'>
            <div><h1>Profile</h1></div>
            <div className='setting-profile-pheader'>
              <div className='setting-profile-ph-img'><img src={profile} alt='No Pic' /></div>
              <div>Profile Picture</div>
            </div>
            <div className='sp-bs-header'>
              <div><h3>Personal Information</h3></div>
              {
                personalinfoedit ? <div className='sp-cancel-sace'>
                  <div className='sp-cs-btn' onClick={onCancel}>Cancel</div>
                  <div className='sp-cs-btn' onClick={personalInfoSave}>Save</div>
                </div> :
                <div className='sp-bs-h-edit' onClick={()=>openEditor('personal')}><Icon icon="iconamoon:edit-light" />Edit</div>
              }
            </div>
            {
              personalinfoedit ? <div className='form-container'>
                <form>
                  <div className='form-box-1'>
                    <label>First Name</label>
                    <input
                    type='text'
                    name='firstName'
                    value={personalinfo.firstName}
                    onChange={handlePIChange}
                    placeholder='Last Name'
                    />
                  </div>
                  <div className='form-box-1'>
                    <label>Last Name</label>
                    <input
                    type='text'
                    name='lastName'
                    value={personalinfo.lastName}
                    onChange={handlePIChange}
                    placeholder='Last Name'
                    />
                  </div>
                  <div className='form-box-2'>
                    <label>Organization</label>
                    <input
                    type='text'
                    name='organization'
                    value={personalinfo.organization}
                    onChange={handlePIChange}
                    placeholder='Organization'
                    />
                  </div>
                </form>
              </div> :
              <div className='sp-table'>
              <table>
                <tbody>
                  <tr>
                    <td className='table-data-1'>First Name</td>
                    <td className='table-data-2'>{personalDetails.firstName}</td>
                  </tr>
                  <tr>
                    <td className='table-data-1'>Last Name</td>
                    <td className='table-data-2'>{personalDetails.lastName}</td>
                  </tr>
                  <tr>
                    <td className='table-data-1'>Industry</td>
                    <td className='table-data-2'>{personalDetails.organization}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            }
            <div className='sp-bs-header'>
              <div><h3>Contact Details</h3></div>
              {
                contactdetailsedit ? <div className='sp-cancel-sace'>
                  <div className='sp-cs-btn' onClick={onCancel}>Cancel</div>
                  <div className='sp-cs-btn' onClick={contactDetailsSave}>Save</div>
                </div> :
                <div className='sp-bs-h-edit'onClick={()=>openEditor('contact')}><Icon icon="iconamoon:edit-light" />Edit</div>
              }
            </div>
            {
              contactdetailsedit ? <div className='form-container'>
              <form>
                <div className='form-box-2'>
                  <label>Email</label>
                  <input
                  type='email'
                  name='email'
                  value={contactinfo.email}
                  onChange={handleCIChange}
                  placeholder='Email'
                  />
                </div>
                <div className='form-box-2'>
                  <label>Phone Number</label>
                  <input
                  type='text'
                  name='phoneNumber'
                  value={contactinfo.phoneNumber}
                  onChange={handleCIChange}
                  placeholder='Phone Number'
                  />
                </div>
              </form>
            </div> :
              <div className='sp-table'>
              <table>
                <tbody>
                  <tr>
                    <td className='table-data-1'>Email</td>
                    <td className='table-data-2'>{contactDetails.email}</td>
                  </tr>
                  <tr>
                    <td className='table-data-1'>Phone</td>
                    <td className='table-data-2'>{contactDetails.phoneNumber}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            }
            <div className='sp-bs-header'>
              <div><h3>Address</h3></div>
              {
                addressedit ? <div className='sp-cancel-sace'>
                <div className='sp-cs-btn' onClick={onCancel}>Cancel</div>
                <div className='sp-cs-btn' onClick={addressEditSave}>Save</div>
              </div> :
                <div className='sp-bs-h-edit' onClick={()=>openEditor('address')}><Icon icon="iconamoon:edit-light" />Edit</div>
              }
            </div>
            {
              addressedit ? <div className='form-container'>
              <form>
                <div className='form-box-1'>
                  <label>Home</label>
                  <input
                  type='text'
                  name='house'
                  value={addressinfo.house}
                  onChange={handleAIChange}
                  placeholder='House # 00, Street # 0'
                  />
                </div>
                <div className='form-box-1'>
                  <label>City</label>
                  <input
                  type='text'
                  name='city'
                  value={addressinfo.city}
                  onChange={handleAIChange}
                  placeholder='Sample City'
                  />
                </div>
                <div className='form-box-1'>
                  <label>Country</label>
                  <input
                  type='text'
                  name='country'
                  value={addressinfo.country}
                  onChange={handleAIChange}
                  placeholder='Sample Country'
                  />
                </div>
                <div className='form-box-1'>
                  <label>Postal Code</label>
                  <input
                  type='text'
                  name='postalNumber'
                  value={addressinfo.postalNumber}
                  onChange={handleAIChange}
                  placeholder='#0000'
                  />
                </div>
              </form>
            </div> : <div className='sp-table'>
              <table>
                <tbody>
                  <tr>
                    <td className='table-data-1'>Home</td>
                    <td className='table-data-2'>{address.house}</td>
                  </tr>
                  <tr>
                    <td className='table-data-1'>City</td>
                    <td className='table-data-2'>{address.city}</td>
                  </tr>
                  <tr>
                    <td className='table-data-1'>Country</td>
                    <td className='table-data-2'>{address.country}</td>
                  </tr>
                  <tr>
                    <td className='table-data-1'>Postal Code</td>
                    <td className='table-data-2'>{address.postalNumber}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingProfile
