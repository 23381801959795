import React from "react";
import { Route, Routes } from "react-router-dom";
import EntranceRoutes from "./Entrance/EntranceRoutes";
import ForgetPasswordRoutes from "./ForgetPassword/ForgetPasswordRoutes";
import SettingRoutes from "./Settings/SettingRoutes";
import DashboardRoutes from "./Dashboard/DashboardRoutes";
import ContactUsRouting from "./ContactUs/ContactUsRouting";

function CraftProMain() {
  return (
    <div>
      <Routes>
        
        <Route path="/*" element ={<EntranceRoutes/>} />

        <Route path="/forgetpassword/*" element={<ForgetPasswordRoutes/>} />

        <Route path="/setting/*" element={<SettingRoutes/>} />

        <Route path="/dashboard/*" element={<DashboardRoutes/>} />

        <Route path="/contactus/*" element={<ContactUsRouting/>} />

      </Routes>
    </div>
  );
}

export default CraftProMain;
