import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Icon } from '@iconify/react/dist/iconify.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Register necessary components for the Line chart
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);



const Chart = () => {
  const chartRef = useRef(null);

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', "May", "Jun", "Jul", "Aug"],
    datasets: [{
      label: 'Sample Data',
      data: [20, 15, 18, 25, 24, 30, 35, 32],
      borderColor: 'black',
      backgroundColor: 'white',
      borderWidth: 4,
      tension: 0.4,
      pointRadius: 0,        // Hide the points by default
      pointHoverRadius: 6,   // Show the points with radius on hover
      pointHoverBackgroundColor: 'black', // Color of the point on hover
      pointHoverBorderColor: 'white',     // Border color of the point on hover
      pointHoverBorderWidth: 2 // Border width of the point on hover
    }]
  };

  const options = {
    scales: {
      y: {
        grid: {
          display: false,
          color: 'rgba(0, 0, 0, 0.1)', 
          drawBorder: false, // Disable the border to create a gap
          offset: true, 
        },
        border: {
            display: false,
        },
        ticks: {
            font: {
              weight: 'semi-bold',  
              size: 12
            },
            padding: 30,
            color: '#000000'    // Font color for x-axis labels (optional)
          },
      },
      x: {
        grid: {
        //   display: false,
          lineWidth: 30, 
          color: 'rgba(0, 0, 0, 0.1)',
          drawBorder: false,
        },
        border: {
            display: false,
        },
        ticks: {
            font: {
              weight: 'semi-bold',  
              size: 12
            },
            padding: 15,
            color: '#000000'    // Font color for x-axis labels (optional)
          },
      }
    },
  };
  
  return (
    <div className='w-full h-full px-[5%] py-8 border border-slate-200 rounded-2xl'>
      <div className='flex justify-between items-center pb-2 relative'>
        <h1 className='text-lg lg:text-2xl font-semibold'>Total Projects Created</h1>
        <button className='py-2 px-6 bg-black text-white text-xs lg:text-sm rounded-full'>2023</button>
      </div>
      <h1 className='text-3xl lg:text-5xl font-semibold pb-4'>58</h1>
      <div className='w-full'>
      <Line style={{width: "100%"}} ref={chartRef} data={data} options={options} />
      </div>
    </div>
  );
};

export default Chart;
