import React, { useContext, useEffect, useState } from 'react'
import './Signup.css'
import { Icon } from '@iconify/react/dist/iconify.js'
import VerificationCommon from '../Common Components/Verification Common/VerificationCommon';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ApiState } from '../../../Context/ApiProvider';
import { Bounce, Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Signup = () => {
  const {user, setUser, corporate, setCorporate} = ApiState()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [buisnessName, setBuisnessName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [verifyPage, setVerifyPage] = useState(false)
  const [checked, setChecked] = useState('checkbox2');
  const [loading,setLoading] = useState('')
  const [errors, setErrors] = useState({});
  const loginBtn = 'login'
  let validationErrors = {};

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate()
  console.log(verifyPage);

  const handleclick = async () => {

    if(checked === "checkbox2"){
      console.log("individual user");

      if(!email || !password || !firstName || !lastName){
      if (!firstName) validationErrors.firstName = "First name is required.";
      if (!lastName) validationErrors.lastName = "field required.";
      if (!email) validationErrors.email = "Email is required.";
      if (!password) validationErrors.password = "Password is required.";
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        console.log(errors);
        toast.error('Please fill in all fields.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          });
        return;
      }
    }
      try {
        setLoading('Loading.....')
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/individualusers`,{
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
        }).then((response)=>{
          console.log(response)
          localStorage.setItem("user", JSON.stringify(response.data))
          setUser(response.data)
          console.log("inside response", response.data);
          setLoading('...Done')
          setVerifyPage(true)
        })
      } catch (error) {
        console.log(error);
        if(error.status === 409){
          toast.warning("user is already registered.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
            });
        }
      }
    }
    else if (checked === "checkbox1"){
      console.log("corporate user");

      if(!email || !password || !buisnessName || !firstName){
        if (!firstName) validationErrors.firstName = "First name is required.";
      if (!lastName) validationErrors.lastName = "Last name is required.";
      if (!buisnessName) validationErrors.lastName = "Buisness name is required.";
      if (!email) validationErrors.email = "Email is required.";
      if (!password) validationErrors.password = "Password is required.";
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        toast.error('Please fill in all fields.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          });     
        return;
      }
      }
      try {
        setLoading('Loading.....')
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/corporateusers`,{
          email: email,
          password: password,
          first_name: "ghazanfar",
          last_name: "rizvi",
          corporate_name: buisnessName,
        }).then((response)=>{
          console.log(response)
          localStorage.setItem("user", JSON.stringify(response.data))
          setUser(response.data)
          console.log("inside response", response.data);
          setLoading('...Done')
          toast.success("Success", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
            });
          setVerifyPage(true)
        })
      } catch (error) {
        console.log(error);
        if(error.status === 409){
          toast.warning("user is already registered.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
            });
        }
      }
    }
  }
  useEffect(()=>{
    console.log(loading)
  },[loading])
function toLogin () {
  navigate('/')
}


  // Toggle function to change the state of the checkboxes
  const handleToggle = (checkbox) => {
    setChecked(checkbox);
  }

  return (
    <>
    <VerificationCommon verifyPage={verifyPage} setVerifyPage={setVerifyPage} loginBtn={loginBtn}>
    <div className='h-full flex justify-center items-center'>
      <div className='px-[5%] pb-10 lg:py-10 text-left max-w-[500px] w-full'>

        <div className='pb-4'>
          <h1 className='text-2xl font-bold'>Signup with email.</h1>
        </div>

        <div className='pb-4'>
          <p className='font-semibold text-sm pb-1'>Select type</p>
          <div className='flex gap-4'>
            <div className={checked === "checkbox1" ? 'border-2 border-[#294DD1] bg-blue-100 py-4 px-4 rounded-xl' : 'border border-slate-300 py-4 px-4 rounded-xl'}>
              <div className='flex justify-between pb-2 items-center'>
                <h1 className='font-semibold text-sm'>Organization</h1>
                <label className="custom-checkbox-container flex items-center">
                  <input type="checkbox" className="custom-checkbox"
                    checked={checked === 'checkbox1'}
                    onChange={() => handleToggle('checkbox1')}
                  />
                  <span className="custom-checkmark"></span>
                </label>
                </div>
              <p className='text-xs text-slate-600'>Create an account for your company or organization</p>
            </div>

            <div className={checked === "checkbox2" ? 'border-2 border-[#294DD1] bg-blue-100 py-4 px-4 rounded-xl' : 'border border-slate-300 py-4 px-4 rounded-xl'}>
              <div className='flex justify-between pb-2 items-center'>
                <h1 className='font-semibold text-sm'>Individual</h1>
                <label className="custom-checkbox-container flex items-center">
                  <input type="checkbox" className="custom-checkbox"
                    checked={checked === 'checkbox2'}
                    onChange={() => handleToggle('checkbox2')}
                  />
                  <span className="custom-checkmark"></span>
                </label>
              </div>
              <p className='text-xs text-slate-600'>Create an account for your personal and private project</p>
            </div>
          </div>
        </div>
        <div className='flex items-center w-full gap-3 pb-2'>
          <div className='w-full'>
            <label htmlFor='first name' className='text-xs pb-1 font-semibold'>First Name <span className='text-red-500 text-base'>*</span></label>
            <div className='relative'>
            <input onChange={(e) => setFirstName(e.target.value)} value={firstName} type="text" id='first name' className={`${errors.firstName ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`} placeholder='John' />
            <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
              {errors.firstName && (
                <Icon icon="fluent:info-24-regular" width="24" height="24"  style={{color: "red"}} />
              )}
            </div>
            </div>
          </div>
          <div className='w-full'>
            <label htmlFor='last name' className='text-xs pb-1 font-semibold'>Last Name</label>
            <div className='relative'>
            <input onChange={(e) => setLastName(e.target.value)} value={lastName} id='last name' type="text" className={`${errors.lastName ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`} placeholder='Doe' />
            <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
              {errors.lastName && (
                <Icon icon="fluent:info-24-regular" width="24" height="24"  style={{color: "red"}} />
              )}
            </div>
            </div>
          </div>
        </div>
        {checked === 'checkbox1' ? (
          <div className='pb-2'>
          <label htmlFor='email' className='text-xs pb-1 font-semibold'>Buisness Name <span className='text-red-500 text-base'>*</span></label>
          <div className='relative'>
          <input onChange={(e) => setBuisnessName(e.target.value)} value={buisnessName} id='email' className={`${errors.buisnessName ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`} type="email" placeholder='XYZ Pvt. Ltd.' />
            <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
              {errors.buisnessName && (
                <Icon icon="fluent:info-24-regular" width="24" height="24"  style={{color: "red"}} />              )}
            </div>
            </div>
        </div>
        ):(
        <div className='hidden'></div>
        )}

        <div className='pb-2'>
          <label htmlFor='email' className='text-xs pb-1 font-semibold'>Email <span className='text-red-500 text-base'>*</span></label>
          <div className='relative'>
          <input onChange={(e) => setEmail(e.target.value)} value={email} id='email' className={`${errors.email ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`} type="email" placeholder='example@gmail.com' />
            <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
              {errors.email && (
                <Icon icon="fluent:info-24-regular" width="24" height="24"  style={{color: "red"}} />
              )}
            </div>
            </div>
        </div>

        <div className='pb-2'>
          <label htmlFor='pass' className='text-xs pb-1 font-semibold'>Password <span className='text-red-500 text-base'>*</span></label>
          <div className='relative z-0'>
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type={showPassword ? 'text' : 'password'}
              id='pass'
              placeholder='Enter your password'
              className={`${errors.password ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`}
            />
            <button
              type='button'
              onClick={handleTogglePassword}
              className='absolute inset-y-0 right-0 flex items-center pr-3'
            >
              <Icon icon={showPassword ? "iconamoon:eye-off-bold" : "lucide:eye"} width="24" height="24" style={{ color: '#294DD1' }} />
            </button>
          </div>
        </div>

        <button onClick={handleclick} className='w-full text-sm bg-black text-white py-3 rounded-full mt-2' >Sign up</button>
        <p className='mt-2 text-xs text-slate-600 hidden lg:block'>You don't have an account? <span className='text-black font-semibold' onClick={toLogin}>Login</span></p>
        <p className='mt-2 text-xs text-slate-600'>By signing up you agree to the <span className='text-black font-semibold'>Terms of services</span> & <span className='text-black font-semibold'>Privacy Policies</span></p>

      </div>
    </div>
    </VerificationCommon>
    </>
  )
}

export default Signup
