import { useContext, useEffect } from 'react';
import axios from 'axios';
import { ApiState } from '../Context/ApiProvider';

const useAxios = () => {
  const { token, refreshToken, setToken } = ApiState(); // Import token and refreshToken

  // Create Axios instance
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });


  // Add response interceptor to handle token refresh
  // useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        const requestRetry = error.config._retry
        if (error.response?.status === 401  && !requestRetry ) {
          error.config._retry=true



          try {
            // Refresh token API call
            const { data } = await axios.post(
              `${process.env.REACT_APP_SERVER_URL}/tokens/renew_access`,
              {
                refresh_token: refreshToken,
              }
            );

            // Update token in local storage and context
            localStorage.setItem('token', JSON.stringify(data.access_token));
            setToken(data.access_token)

            // setToken(data.access_token); // Update token in context

            // Retry the original request with the new access token
            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
            return axiosInstance(originalRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );
  // }, [refreshToken, setToken,token,axios]);

  return axiosInstance;
};

export default useAxios;
