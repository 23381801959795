import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LeftSettings from './CommonComponents/LeftSettings/LeftSettings'
import Main from './Main/Main'
import ChartPage from './ChartPage/ChartPage'
import CreateApi from './CreateApi/CreateApi'
import ApiCrafted from './ApiCrafted/ApiCrafted'

function DashboardRoutes() {
  return (
    <div>
      <Routes>
        <Route path='' element={
                <Main/>
        } />
        <Route path='projects' element={
            <LeftSettings>
                <ChartPage/>
            </LeftSettings>
        } />
        <Route path='createapi' element={
            <CreateApi/>
        } />
        <Route path='apicrafted' element={
            <ApiCrafted/>
        } />
      </Routes>
    </div>
  )
}

export default DashboardRoutes
