import React from 'react'
import { Route, Routes } from 'react-router-dom'
import VerificationCommon from './Common Components/Verification Common/VerificationCommon'
import Login from './Login Page/Login'
import Signup from './SignUp/Signup'
import InfoPage from './InfoPage/InfoPage'
import FinalEntrance from './FinalEntrance/FinalEntrance'

function EntranceRoutes() {
  return (
    <div>
      <Routes>

        <Route path='' element = {
                <Login/>
        } />

        <Route path='signup' element = {
                <Signup/>
                } />
        
        <Route path='infopage' element={
                <InfoPage/>
        } />

        <Route path='congrats' element={
                <FinalEntrance/>
        } />

      </Routes>
    </div>
  )
}

export default EntranceRoutes
