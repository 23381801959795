import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useState } from 'react'
import Navbar from '../../Dashboard/CommonComponents/Navbar/Navbar'
import { useNavigate } from 'react-router-dom'

function Support() {
  const [activePage, setActivePage] = useState('support')
  const [statusChange, setStatusChange] = useState('suggestion')
  const navigate = useNavigate()
  function toFaqs () {
    navigate('/contactus/faqs')
    setActivePage('faq')
  }
  return (
    <div className='bg-[#F5F5F5]'>
      <Navbar/>
      <div className='flex px-[5%]'>
        <div className='pt-12'>
            <div onClick={()=> setActivePage('support')} className={activePage === 'support' ? 'flex items-center gap-2 mb-4 bg-black text-white py-3 px-10 rounded-full':'flex items-center gap-2 border border-black mb-4 text-black py-3 px-10 rounded-full'}>
            <Icon icon="ph:phone-light" width={24} height={24}/>
            <button>Support</button>
            </div>
            <div onClick={toFaqs} className={activePage === 'faq' ? 'flex items-center gap-2 mb-4 bg-black text-white py-3 px-10 rounded-full':'flex items-center gap-2 border border-black mb-4 text-black py-3 px-10 rounded-full'}>
            <Icon icon="mdi:message-question-outline" width="24" height="24" className='pt-1'/>
            <button>FAQs</button>
            </div>
        </div>
        <div className='w-full py-12 pl-[10%]'>
            <div className='py-8 w-fit px-[15%] bg-white border border-slate-200 rounded-2xl'>
             <div className='max-w-[550px] w-full'>
               <div className="pb-12">
                 <h1 className="text-4xl font-bold">Contact us</h1>
               </div>
                <div>
                    <div className='flex justify-between pb-4'>
                        <div className='w-32'>
                            <button onClick={()=> setStatusChange('suggestion')} className={statusChange === 'suggestion' ? 'w-full bg-black text-white py-2 px-4 rounded-full':'w-full text-black border border-black py-2 px-4 rounded-full'}>Suggestion</button>
                        </div>
                        <div className='w-32'>
                            <button onClick={()=> setStatusChange('complaint')} className={statusChange === 'complaint' ? 'w-full bg-black text-white py-2 px-4 rounded-full':'w-full text-black border border-black py-2 px-4 rounded-full'}>Complaint</button>
                        </div>
                        <div className='w-32'>
                            <button onClick={()=> setStatusChange('request')} className={statusChange === 'request' ? 'w-full bg-black text-white py-2 px-4 rounded-full':'w-full text-black border border-black py-2 px-4 rounded-full'}>Request</button>
                        </div>
                        <div className='w-32'>
                            <button onClick={()=> setStatusChange('praise')} className={statusChange === 'praise' ? 'w-full bg-black text-white py-2 px-4 rounded-full':'w-full text-black border border-black py-2 px-4 rounded-full'}>Praise</button>
                        </div>
                    </div>
                    <div className='pb-4'>
                        <label htmlFor="subject" className="font-semibold pb-1">Subject <span className="text-red-500 text-base">*</span></label>
                        <input type='text' placeholder='Subject' id='subject' className="border border-slate-400 p-2 w-full rounded-lg"/>
                    </div>
                    <div>
                    <div className="relative">
                      <textarea
                        rows={"8"}
                        placeholder=""
                        className="w-full border border-slate-400 rounded-xl p-4 pr-12"
                      />
                      <div className="absolute bottom-3 px-3 py-3 border-t border-slate-200 w-full"> 
                        <label htmlFor="file">
                          <Icon icon="ph:paperclip" width="24" height="24" className="cursor-pointer text-[#767676]"/>
                    </label>
                        <input type="file" id="file" className="hidden" />
                      </div>
                    </div>

                        <div className='pb-4'>                          
                            <div className='text-[#767676] font-semibold text-sm'>
                            Attach the screen shot of the issue. Attached file should be less then 10 MB.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-4'>
                <button className='w-full bg-black text-white py-3 px-4 rounded-full'>Send</button>
                </div>
                <div className='flex gap-2'>
                    <div className='px-4 py-4 border border-slate-400 rounded-xl w-full cursor-pointer'>
                        <div className='pb-4'>
                        <Icon icon="carbon:location" width={48} height={48}/>
                        </div>
                        <div className='text-xl font-bold pb-2'>Address</div>
                        <div className='max-w-[150px]'>Sharjah, United Arab Emirates</div>
                    </div>
                    <div className='px-4 py-4 border border-slate-400 rounded-xl w-full cursor-pointer'>
                    <div className='pb-4'>
                    <Icon icon="ic:outline-email" width={48} height={48}/>
                    </div>
                    <div className='text-xl font-bold pb-2'>Email</div>
                    <div className='max-w-[150px]'>info@naviscom.com</div>
                    </div> 
                    <div className='px-4 py-4 border border-slate-400 rounded-xl w-full cursor-pointer'>
                    <div className='pb-4'>
                    <Icon icon="ph:phone-light" width={48} height={48}/>
                    </div>
                    <div className='text-xl font-bold pb-2'>Phone</div>
                    <div>
                    +971 5 88174234
                    </div>
                    </div>
                </div>

             </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Support
