import React from 'react'
import lock from "../../Assets/Images/Group2.png"

const VerifyPhone = () => {
  return (
    <div className=''>
      <div className='bg-white border border-slate-50 w-fit rounded-2xl'>
        <div className='max-w-[550px] w-full py-14 px-28 flex justify-center items-center flex-col text-center'>
            <div className='pb-4'>
                <img src={lock} alt="lock" />
            </div>
            <div className='pb-4'>
                <h1 className='text-2xl font-bold pb-3'>Confirm Your Phone Number</h1>
                <p className='font-semibold text-xs text-slate-600'>Enter the 6-digits code we just sent to +1 429 456 2347</p>
            </div>
            <div className='flex gap-3 pb-6'>
                <input type="text" className='w-12 h-12 rounded-lg border border-slate-400' />
                <input type="text" className='w-12 h-12 rounded-lg border border-slate-400' />
                <input type="text" className='w-12 h-12 rounded-lg border border-slate-400' />
                <input type="text" className='w-12 h-12 rounded-lg border border-slate-400' />
                <input type="text" className='w-12 h-12 rounded-lg border border-slate-400' />
                <input type="text" className='w-12 h-12 rounded-lg border border-slate-400' />
            </div>
            <button className='w-full bg-black text-sm text-white py-3 rounded-full font-semibold'>Verify Now</button>
            <p className='mt-4 text-sm text-slate-600'>Wait for <span className='text-black font-semibold'>1:30</span> seconds before requesting new code.</p>
        </div>
      </div>
    </div>
  )
}

export default VerifyPhone
