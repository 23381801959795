import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const LeftSettings = ({children}) => {
  const navigate = useNavigate() 
  function toSetting () {
    navigate('/setting/profilesetting')
  }
  function toLogin () {
    navigate('/')
  }
  function toSupport () {
    navigate('/contactus/support')
  }
  return (
    <div className='flex'>
      <div className='fixed 2xl:w-24 w-20 h-screen hidden lg:flex flex-col justify-end 2xl:px-6 px-4 pb-6 border-r border-slate-400'>
        <div className='flex flex-col items-center gap-1 py-2 text-slate-500 hover:text-black' onClick={toSupport}>
        <button><Icon icon="ph:phone" width="1.2em" height="1.2em" /></button>
        <p className='text-xs'>Support</p>
        </div>
        <div className='flex flex-col items-center gap-1 py-2 text-slate-500 hover:text-black' onClick={toSetting}>
        <button><Icon icon="mingcute:settings-6-line"width="1.2em" height="1.2em" /></button>
        <p className='text-xs'>Settings</p>
        </div>
        <div className='h-[1px] bg-slate-500 my-2'></div>
        <div className='flex flex-col items-center gap-1 py-2 pb-6' onClick={toLogin}>
        <button><Icon icon="hugeicons:logout-05" width="1.2rem" height="1.2rem" className='text-red-600' /></button>
        <p className='text-red-600 text-xs'>Logout</p>
        </div>
      </div>

      <div className='w-full lg:pl-20 2xl:pl-24'>
       {children}
      </div>
    </div>
  )
}

export default LeftSettings
