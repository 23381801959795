import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'
import VerificationCommon from '../../Entrance/Common Components/Verification Common/VerificationCommon'
import { useNavigate } from 'react-router-dom'

const ForgetPage = () => {
  const navigate =useNavigate()
  function toNext () {
    navigate('/forgetpassword/resetpassword')
  }
  return (
    <>
    <VerificationCommon>
    <div className='h-full flex justify-center items-center'>
      <div className='px-3 py-3 text-left max-w-[500px] w-full'>
        <div className='pb-4'>
            <button className='py-2 pl-3 pr-1 bg-slate-100 rounded-full'>
            <Icon icon="material-symbols-light:arrow-back-ios" width="24" height="24"  style={{color: "#294DD1"}} />
            </button>
        </div>
        <div className='pb-4'>
            <h1 className='text-2xl font-bold'>Forget Password</h1>
        </div>
        <div className='pb-6'>
            <p className='text-slate-600 text-sm'>Please enter the email associated with you account.</p>
        </div>
        <div className='pb-6'>
          <label htmlFor='email' className='font-semibold text-sm pb-1'>Email <span className='text-red-500 text-base'>*</span></label>
          <input id='email' className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none" type="text" placeholder='example@gmail.com'/>
        </div>
        <button className='w-full bg-black text-white py-3 rounded-full text-sm' onClick={toNext}>Submit</button>
      </div>
    </div>
    </VerificationCommon>
    </>
  )
}

export default ForgetPage
