import React, { useEffect, useState } from 'react'
import SettingSideBar from '../../../SettingSideBar/SettingSideBar'
import Navbar from '../../../../Dashboard/CommonComponents/Navbar/Navbar'
import CreateNewUser from '../../../SettingModals/CreateNewUser/CreateNewUser'
import { Icon } from '@iconify/react/dist/iconify.js'
import { ApiState } from '../../../../../Context/ApiProvider'
import useAxios from '../../../../../utils/axiosInstance'
import SubUsers from '../SubUsers/SubUsers'

function AddSubUser() {
  const [showModal, setShowModal] = useState(false)
  const {token} = ApiState()
  const [subUsers, setsubUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const axiosInstance = useAxios()

  const fetchData = async () => {
    try {
      const {data} = await axiosInstance.get(`${process.env.REACT_APP_SERVER_URL}/subusers3`,{
        headers:{
          authorization: `Bearer ${token}`
        }
     
      })
      setsubUsers(data);
      console.log(subUsers);
    } catch (error) {
      console.log(error);    
    } 
  };

  useEffect(() => {
    fetchData(); 
  }, [showModal]);

  return (
    <div className='min-h-screen bg-[#f5f5f5]'>
      {showModal && (
    <>
      {/* Background overlay with opacity */}
      <div onClick={()=> setShowModal(false)} className='fixed top-0 bottom-0 left-0 right-0 bg-[#F5F5F5] opacity-65 z-10'></div>

      {/* Modal content */}
      <div className='fixed top-[50%] z-20 left-[50%] translate-x-[-50%] translate-y-[-50%]'>
        <CreateNewUser showModal={showModal} setShowModal={setShowModal} />
      </div>
    </>
  )}
    {subUsers.length > 0 ? (
      <SubUsers data={subUsers} setShowModal={setShowModal}/>
    ):(
      <>
      <div><Navbar/></div>
      <div className='flex bg-[#f5f5f5] justify-start gap-[15%] w-full p-10 '>
        <div className=''>
          <SettingSideBar activeState={'sub-user'}/>
        </div>
        <div className='w-[700px] min-h-[350px] py-5 px-[100px] flex justify-center items-center rounded-2xl bg-white border border-[#CECECE] '>
        <div className='flex items-center w-full'>
        <div className='flex flex-col gap-2 justify-center items-center w-full'>
          <h1 className='text-2xl font-semibold text-[#c7c7c7]'>Looks Like You're Flying Solo!</h1>
          <div onClick={(e) => setShowModal(true)} className='flex cursor-pointer items-center gap-2 text-white bg-black py-2 px-4 rounded-full'>
            <Icon icon="gg:add" width="24" height="24"  style={{color: "white"}} />          
            <button>Add sub user</button>
          </div>
        </div>
        </div>
        </div>
      </div>
      
      </>
    )}
    </div>
  )
}

export default AddSubUser
