import React from 'react'
import top_bg from "../../Assets/Images/Rectangle7.png"
import bottom_middle_bg from "../../Assets/Images/Isolation_Mode.png"
import trophy from "../../Assets/Images/Capa_1.png"
import Navbar from '../CommonComponents/Navbar/Navbar'
import { useNavigate } from 'react-router-dom'

const ApiCrafted = () => {
  const navigate = useNavigate() 
  function toDashboard () {
    navigate('/dashboard')
  }
  function toCreateApi () {
    navigate('/dashboard/createapi')
  }
  return (
    <div>
        <Navbar/>
      <div className='w-full h-full flex justify-center items-center py-[2%] px-[5%]'>
        <div className='max-w-[700px] flex w-full flex-col gap-4'>
        <div className='w-full hidden lg:block bg-[#294DD1] py-[3%] px-[5%] rounded-xl relative overflow-hidden'>
        <div className='absolute -top-44 -left-56 z-0 h-full'>
        <img className='h-fit' src={top_bg} alt="top_bg" />
        </div>
        <div className='absolute -bottom-8 right-10'>
        </div>
        <div className='absolute -bottom-20 right-8'>
        <img className='w-48' src={bottom_middle_bg} alt="bottom_middle_bg" />
        </div>
          <div className='pb-6'>
          <h1 className='relative text-3xl text-white font-semibold z-10 pb-3'>Get started with API CreftPro</h1>
          <p className='max-w-[70%] relative text-xs text-white z-10'>Quickly generate API structures and push complete files to GitHub, saving time and streamlining your workflow.</p>
          </div>
          <button className='bg-white text-xs relative z-10 text-black py-2 px-12 rounded-full font-semibold' onClick={toCreateApi}>Create New Project</button>
        </div>

            <div className='flex justify-center items-center pt-8 lg:pt-0 lg:border border-slate-200 rounded-xl'>
            <div className='py-[5%] text-left max-w-[400px]'>
        <div className='pb-4'>
            <img src={trophy} className='w-16' alt="trophy" />
        </div>
        <div className='pb-2'>
            <h1 className='text-2xl font-bold'>Awesome</h1>
        </div>
        <div className='pb-2'>
            <p className='text-slate-600 text-xs'>Your APIs have been created and are now live on github.com/project.</p>
        </div>
        <div className='pb-4'>
            <p className='text-slate-600 text-xs'>All files are pushed successfully, and your project is up-to-date with the latest API structures.</p>
        </div>
        <button className='w-full text-sm bg-black text-white py-3 rounded-full' onClick={toDashboard}>Dashboard</button>

      </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ApiCrafted
