import React, { useState } from 'react'
import SettingSideBar from '../../SettingSideBar/SettingSideBar'
import { Icon } from '@iconify/react/dist/iconify.js'
import './settingpayment.css'
import Navbar from '../../../Dashboard/CommonComponents/Navbar/Navbar'

function SettingPayInfo() {
  const cards = [{name:'visa',number:'923847209'},{name:'mastercard',number:'6573927'}]
  const [cardsEdit,setCardsEdit] = useState(false)
  function Edit () {
    setCardsEdit(!cardsEdit)
  }
  function Done () {
    setCardsEdit(!cardsEdit)
  }
  
  return (
    <div className='setting-pay-info-container'>
      <div><Navbar/></div>
      <div className='setting-payment'>
        <div>
          <SettingSideBar activeState={'payinfo'} />
        </div>
        <div className='settingpayment-outer'>
          <div className='settingpayment-inner'>
            <div>
              <h1>Payment Information</h1>
            </div>
            <div className='sp-bs-header'>
              <div><h3>Payment Information</h3></div>
              {
                cardsEdit ? <div className='sp-cancel-sace'>
                  <div className='sp-cs-btn' onClick={Done}>Done</div>
                </div> : 
                <div className='sp-bs-h-edit' onClick={Edit}><Icon icon="iconamoon:edit-light" />Edit</div>
              }
            </div>
            <div className='cards-container'>
            {
              cards.map((item)=>{
                return(
                  <>
                  <div className='card-body'>
                    <div className='card-upper'>
                      {
                        cardsEdit ? <div><Icon icon="ep:delete"  style={{color: '#ff0000'}} /></div> : <></>
                      }
                      <div className='card-data'>
                      <div>{item.name}</div>
                      <div>{item.number}</div>
                      </div>
                    </div>
                    <div>Logo</div>
                  </div>
                  </>
                )
              })
            }
            </div>
            <div className='add-btn'>
              <button>
              <Icon icon="gridicons:add-outline" /> Add New Card
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingPayInfo
