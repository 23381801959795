import React, { createContext, useContext, useState } from 'react'

const ApiContext = createContext();

const ApiProvider = ({children}) => {
  const [user, setUser]=useState(localStorage.getItem('user')?JSON.parse(localStorage.getItem("user")): null)
  const [corporate, setCorporate]=useState(localStorage.getItem('corporate')?JSON.parse(localStorage.getItem("corporate")): null)
  const [token,setToken] = useState(localStorage.getItem('token')?JSON.parse(localStorage.getItem("token")): null)
  const [refreshToken,setRefreshToken] = useState(localStorage.getItem('refreshToken')?JSON.parse(localStorage.getItem("refreshToken")): null)
  const [subUser, setSubUser] = useState(localStorage.getItem('user')?JSON.parse(localStorage.getItem("subuser")): null)

  return (
    <ApiContext.Provider value={{user, token, setToken, setUser, setRefreshToken,refreshToken, corporate, setCorporate, subUser, setSubUser}}>
        {children}
    </ApiContext.Provider>
  )
}

export const ApiState=()=>{
    return useContext(ApiContext)
}

export default ApiProvider
