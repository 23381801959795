import React from 'react'
import trophy from "../../Assets/Images/Capa_1.png"
import VerificationCommon from '../../Entrance/Common Components/Verification Common/VerificationCommon'
import { useNavigate } from 'react-router-dom'

const PasswordChanged = () => {
  const navigate = useNavigate()
  function toLogin () {
    navigate('/')
  }
  return (
    <>
    <VerificationCommon>
    <div className='h-full flex justify-center items-center'>
      <div className='px-3 py-3 text-left max-w-[500px] w-full'>
        <div className='pb-6'>
            <img src={trophy} alt="trophy" />
        </div>
        <div className='pb-4'>
            <h1 className='text-2xl font-bold'>Password Changed!</h1>
        </div>
        <div className='pb-6'>
            <p className='text-slate-600 text-sm'>Your password has been successfully reset.</p>
        </div>
        <button className='w-full bg-black text-white py-3 rounded-full text-sm' onClick={toLogin}>Go to Login</button>
      </div>
    </div>
    </VerificationCommon>
    </>
  )
}

export default PasswordChanged
