import React, { useEffect, useState } from 'react'
import profileImg from '../../../../Assets/Images/hand-img.jpg'
import { Icon } from '@iconify/react/dist/iconify.js'
import SettingSideBar from '../../../SettingSideBar/SettingSideBar'
import Navbar from '../../../../Dashboard/CommonComponents/Navbar/Navbar'
import axios from 'axios'
import useAxios from '../../../../../utils/axiosInstance'
import { ApiState } from '../../../../../Context/ApiProvider'
import { useNavigate } from 'react-router-dom'

const SubUsers = ({data, setShowModal}) => {
  const navigate = useNavigate()
  console.log(data);

  return (
    <div className='min-h-screen bg-[#f5f5f5]'>
    <div><Navbar/></div>
    <div className='flex bg-[#f5f5f5] justify-start gap-[15%] w-full p-10 '>
      <div className=''>
        <SettingSideBar activeState={'sub-user'}/>
      </div>
      <div className='w-[700px] py-8 px-[100px] flex justify-center items-center rounded-3xl bg-white border border-[#CECECE] '>
      <div className='flex flex-col gap-5 items-start w-full'>
      <div>
        <h1 className='text-2xl font-bold'>Sub Users</h1>
      </div>
      <div className='w-full'>
      <div className='relative w-full'>
        <input
        type='text'
        placeholder='Search'
        className='border border-slate-400 p-2 pl-10 w-full rounded-full'
      />
      <button
        type='button'
        className='absolute inset-y-0 left-0 flex items-center pl-3'
      >
        <Icon icon="ri:search-line" width="24" height="24" className='text-slate-500' />
      </button>
      </div>

      <div className='flex flex-col gap-5 w-full py-7 px-2'>
      {data.map((item)=>(
        <div key={item.id} onClick={() => navigate(`/setting/user-settings/${item.id}`, { state: { user: item } })} className='flex gap-5 items-center w-full'>
          <img className='w-12 h-12 rounded-full' src={profileImg} alt="profileImg" />
          <div>
            <h1 className='font-semibold text-sm'>{item.first_name}</h1>
            {/* <p className='text-sm text-[#767676]'>john.naviscom</p> */}
        </div>
        </div>
      ))}
      </div>

      <div onClick={(e) => setShowModal(true)} c className='flex items-center justify-center w-full gap-2 text-white bg-black py-3 px-4 rounded-full'>
        <Icon icon="gg:add" width="24" height="24"  style={{color: "white"}} />
        <button>Add sub user</button>
      </div>

      </div>
      </div>
      </div>
    </div>
  </div>
  )
}

export default SubUsers
