import React, { useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import VerificationCommon from '../Common Components/Verification Common/VerificationCommon';
import { useNavigate } from 'react-router-dom';
import { ApiState } from '../../../Context/ApiProvider';
import countryList from 'react-select-country-list';
import Select from "react-select"
import axios from 'axios';
import useAxiosInstance from '../../../utils/axiosInstance';
import useAxios from '../../../utils/axiosInstance';
import { Slide, toast } from 'react-toastify';

// className='border border-slate-300 p-2 w-full rounded-lg'

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    width: 100%;

  }

  .country-list {
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .country-list .country {
    padding: 10px;
  }
`;

const InfoPage = () => {
  console.log(process.env.REACT_APP_SERVER_URL)
  const axiosInstance = useAxios()
    const [country, setCountry] = useState(null)
    const [industry, setIndustry] = useState('Industry')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [phone, setPhone] = useState('');
    const [verifyPhone, setVerifyPhone] = useState(false)
    const {user,token, setUser} = ApiState()
    const [errors, setErrors] = useState({});

    let validationErrors = {};

    const navigate = useNavigate()
    const options = countryList().getData();

  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  async function toSuccess () {
    if(!country || !industry || !address || !city || !postalCode || !phone){
      if (!country) validationErrors.country = "Country is required.";
      if (!industry) validationErrors.industry = "Industry is required.";
      if (!address) validationErrors.address = "Address is required.";
      if (!city) validationErrors.city = "City is required.";
      if (!postalCode) validationErrors.postalCode = "Postal code is required.";
      if (!phone) validationErrors.phone = "Phone is required.";
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        console.log(errors);
        toast.error('Please fill in all fields.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          });
        return;
      }
    }
    try {
      // const signupInfo = JSON.parse(localStorage.getItem("signupInfo"))
      console.log(user);
     await axiosInstance.post(`${process.env.REACT_APP_SERVER_URL}/updateusers`,{
      id: user.id,
      phone_number: phone,
      address,
      city,
      country,
      postal_code: postalCode,
      industry,
      state:'sindh'
     },{
      headers:{
        authorization: `Bearer ${token}`
      }
     }).then((response) => {
      console.log(response);
      toast.success("Success", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
        });
      // setVerifyPhone(true)
      navigate("/congrats")
     })

    } catch (error) {
      console.error('error in submiting form of info')
      console.log(error);
    }
  }
  console.log("tokenin  page",token)

  const countries = [
    "United States", "Canada", "United Kingdom", "Australia",
    "Germany", "France", "India", "China", "Brazil", "Japan"
  ];

  const handleCountryChange = (selectedOption) => {
    if (selectedOption) {
      setCountry(selectedOption.value);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? errors.country ? '#FF0000' : '#294DD1' : errors.country ? '#FF0000' : '#d1d5db',
      boxShadow: state.isFocused ? '0 0 0 1px rgba(59, 130, 246, 0.25)' : 'none',
      borderRadius: '0.5rem',
      padding: '0.2rem',
      '&:hover': { borderColor: '#3b82f6' },
    }),
  }
  return (
    <>
    <VerificationCommon verifyPhone={verifyPhone} setVerifyPhone={setVerifyPhone}>
    <div className='h-full flex justify-center items-center'>
      <div className='px-[5%] py-3 text-left max-w-[500px] w-full'>
        <div className='pb-5'>
        <h1 className='text-2xl font-bold'>Complete Your Information</h1>
        </div>

    <div className='flex items-center w-full'>
        <div className='flex items-center w-full max-w-[450px] px-2'>
        <div>
        <Icon icon="teenyicons:tick-circle-solid" width="24" height="24"  style={{color: "#294DD1"}} />
        </div>
        <div className='w-full h-1 bg-[#294DD1]'></div>
        <div>
        <Icon icon="clarity:dot-circle-line" width="24" height="24"  style={{color: "#294DD1"}} />
        </div>
        <div className='w-full h-1 bg-slate-200'></div>
        <div>
        <Icon icon="clarity:dot-circle-line" width="24" height="24"  style={{color: "#294DD1"}} />
        </div>
        </div>
    </div>
    <div className='flex justify-between w-full pb-4'>
        <p className='font-semibold w-10 lg:w-fit text-center text-xs'>Sign up</p>
        <p className='font-semibold pl-3 lg:pl-5 w-16 lg:w-fit text-center text-xs'>Complete Information</p>
        <p className='font-semibold pl-3 lg:pl-5 w-16 lg:w-fit text-center text-xs'>Payment Info</p>
    </div>

    <div className="w-full">
    <label htmlFor="country" className='font-semibold text-xs pb-1'>Country <span className='text-red-500 text-sm'>*</span></label>
      <Select
        options={options}
        value={options.find(option => option.value === country)}
        onChange={handleCountryChange}
        placeholder="Select a country..."
        styles={customStyles}
      />
    </div>

        <div className='pb-3'>
          <label htmlFor='address' className='font-semibold text-xs pb-1'>Address <span className='text-red-500 text-base'>*</span></label>
          <input onChange={(e) => setAddress(e.target.value)} value={address} id='address' className={`${errors.address ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`} type="text" placeholder='House #65, Street 8'/>
        </div>

        <div className='flex w-full gap-3 pb-2'>
            <div className='w-full'>
            <div className='relative'>
            <input onChange={(e) => setCity(e.target.value)} value={city} type="text" className={`${errors.city ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`} placeholder='City'/>
            <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
              {errors.city && (
                <Icon icon="fluent:info-24-regular" width="24" height="24"  style={{color: "red"}} />
              )}
            </div>
            </div>
            </div>
            <div className='w-full'>
            <div className='relative'>
            <input onChange={(e) => setPostalCode(e.target.value)} value={postalCode} type="text" className={`${errors.postalCode ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`} placeholder='Postal Code'/>
            <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
              {errors.postalCode && (
                <Icon icon="fluent:info-24-regular" width="24" height="24"  style={{color: "red"}} />
              )}
            </div>
            </div>
            </div>
        </div>

    <div className='pb-2'>
    <label htmlFor='industry' className='text-xs font-semibold pb-1'>Industry <span className='text-red-500 text-base'>*</span></label>
    <div className='relative'>
      <input
      onChange={(e) => setIndustry(e.target.value)}
        value={industry}
        type='text'
        id='industry'
        placeholder='Select Your Industry'
        className={`${errors.lastName ? "border-red-500 focus:ring-red-200 focus:border-red-700": "border-slate-300 focus:ring-blue-200 focus:border-blue-700"} border p-2 w-full rounded-lg focus:ring-2 focus:ring-opacity-50 focus:outline-none`}
      />
      <button
        type='button'
        className='absolute inset-y-0 right-0 flex items-center pr-3'
      >
        <Icon icon='iconamoon:arrow-down-2-light' width="24" height="24" className='text-slate-600'  />
      </button>
    </div>
    </div>

    <div className='pb-4'>
    <p className='font-semibold text-xs pb-1'>Phone <span className='text-red-500 text-base'>*</span></p>
    <PhoneInput
        country={'us'}
        value={phone}
        onChange={handlePhoneChange}
        inputStyle={{
            width: '100%',
            paddingLeft: '62px',
            boxSizing: 'border-box',
            border: '1px solid #ccc',
            borderRadius: '8px',
            height: '40px',
            paddingTop: "4px",
            paddingBottom: '4px'
          }}
          buttonStyle={{
            position: 'absolute',
            left: '0',
            top: '0',
            height: '40px',
            padding: '0 10px',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            backgroundColor: 'white',
          }}
          containerStyle={{
            position: 'relative',
            width: '100%',
          }}
      />
    </div>

    <button onClick={toSuccess} className='w-full text-sm bg-black text-white py-3 rounded-full mt-2'>Submit</button>

      </div>
    </div>
    </VerificationCommon>
    </>
  )
}

export default InfoPage
