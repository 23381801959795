import React, { useRef, useState } from 'react';
import lock from "../../Assets/Images/Group2.png";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ApiState } from '../../../Context/ApiProvider';

const VerifyEmail = ({ verifyPage, setVerifyPage }) => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const navigate = useNavigate();
  const { user, setUser, setToken, setRefreshToken } = ApiState();
  const [loading, setLoading] = useState(false);

  const inputRefs = useRef([]);

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus(); // Focus previous input if empty
    } else if (e.key === 'Backspace') {
      const newOtp = [...otp]; // Copy OTP array
      newOtp[index] = ''; // Clear current input
      setOtp(newOtp); // Update OTP state
      console.log(otp);
      
    } else if (/^[0-9]$/.test(e.key)) { // Only allow digits
      const value = e.key;
      const newOtp = [...otp]; // Copy OTP array
      newOtp[index] = value; // Set current digit
      setOtp(newOtp); // Update OTP state
      console.log(otp);
      
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus(); // Move to the next input
      }
    }
  };

  const handleVerify = async () => {
    try {
      const otpString = otp.join(''); // Join OTP array into a string
      console.log(otpString);

      setLoading(true); // Set loading state before making the request

      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/verifyemailVerificationCode`, {
        email: user?.email,
        email_verification_code: otpString,
      });

      console.log("verify email", response);

      // Update user data and tokens
      setUser(response.data.user);
      setToken(response.data.token.access_token);
      setRefreshToken(response.data.token.refresh_token);

      // Save data to local storage
      localStorage.setItem('token', JSON.stringify(response.data.token.access_token));
      localStorage.setItem('user', JSON.stringify(response.data.user));
      localStorage.setItem('refreshToken', JSON.stringify(response.data.token.refresh_token));

      navigate("/infopage"); // Navigate to the info page
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className=''>
      <div className='bg-white border border-slate-50 w-fit rounded-2xl'>
        <div className='max-w-[550px] py-14 px-28 w-full flex justify-center items-center flex-col text-center'>
          <div className='pb-4'>
            <img src={lock} alt="lock" />
          </div>
          <div className='pb-4'>
            <h1 className='text-2xl font-bold pb-3'>Confirm your email address</h1>
            <p className='font-semibold text-xs text-slate-600'>
              Enter the 6-digits code we just sent to {user?.email}
            </p>
          </div>
          <div className='flex gap-3 pb-5'>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                className='w-14 h-14 rounded-lg border border-slate-400 text-center'
                onKeyDown={(e) => handleBackspace(e, index)}
                ref={(el) => (inputRefs.current[index] = el)} // Assign ref to input
              />
            ))}
          </div>
          <button
            onClick={handleVerify}
            disabled={loading}
            className='w-full bg-black text-sm text-white py-3 rounded-full font-semibold'>
            {loading ? 'Verifying...' : 'Verify Now'}
          </button>
          <p className='mt-4 text-slate-600'>
            Wait for <span className='text-black font-semibold'>1:30</span> seconds before requesting new code.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
