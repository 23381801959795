import React, { useState } from 'react'
import Navbar from '../../Dashboard/CommonComponents/Navbar/Navbar';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router-dom';

const FAQS = () => {
  const [activePage, setActivePage] = useState('faq')
    const [openIndex, setOpenIndex] = useState(null);
    const navigate = useNavigate()
    function toSupport () {
      navigate('/contactus/support')
      setActivePage('support')
    }

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'What is the purpose of this app?',
      answer: 'This app helps you manage and organize your tasks efficiently.',
    },
    {
      question: 'How do I reset my password?',
      answer: 'You can reset your password by clicking on the "Forgot Password" link on the login page.',
    },
    {
      question: 'Is there a mobile version of the app?',
      answer: 'Yes, the app is available on both iOS and Android platforms.',
    },
  ];
      
  return (
    <div className='bg-[#F5F5F5] min-h-screen'>
      <Navbar/>
      <div className='flex px-[5%]'>
        <div className='pt-12'>
            <div onClick={toSupport} className={activePage === 'support' ? 'flex items-center gap-2 mb-4 bg-black text-white py-3 px-10 rounded-full':'flex items-center gap-2 border border-black mb-4 bg-white text-black py-3 px-10 rounded-full'}>
            <Icon icon="ph:phone-light" width={24} height={24}/>
            <button>Support</button>
            </div>
            <div onClick={()=> setActivePage('faq')} className={activePage === 'faq' ? 'flex items-center gap-2 mb-4 bg-black text-white py-3 px-10 rounded-full':'flex items-center gap-2 border border-black mb-4 bg-white text-black py-3 px-10 rounded-full'}>
            <Icon icon="mdi:message-question-outline" width={24} height={24} />
            <button>FAQs</button>
            </div>
        </div>
        <div className='w-full py-12 pl-[10%]'>
        <div className='py-8 px-[15%] w-fit border bg-white border-slate-200 rounded-2xl'>
  <div className='w-full max-w-[550px] sm:px-6'>
    <div className='w-full'>
      <div className="pb-8">
        <h1 className="text-4xl font-bold">Frequently Asked Questions</h1>
      </div>
      <div className='w-full'>
        {faqs.map((faq, index) => (
          <div key={index} className="border-b w-full">
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full text-left py-4 text-[#252424] text-lg font-normal flex justify-between items-center"
            >
              <span>{faq.question}</span>
              <span><Icon icon={openIndex === index ? 'iconamoon:arrow-up-2-fill' : 'iconamoon:arrow-down-2-fill'} width="24" height="24"  style={{color: "black"}} /></span>
            </button>
            {openIndex === index && (
              <div className="py-4">
                <p className="text-gray-700 max-w-[470px]">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
  </div>
</div>


      </div>
    </div>
  )
}

export default FAQS
