import React from 'react'
import trophy from '../../Assets/Images/Capa_1.png'
import VerificationCommon from '../Common Components/Verification Common/VerificationCommon'
import { useNavigate } from 'react-router-dom'

const FinalEntrance = () => {
  const navigate = useNavigate()
  function toDashboard (){
    navigate("/dashboard")
  }

  return (
    <>
    <VerificationCommon>
    <div className='h-full flex justify-center items-center'>
      <div className='px-[5%] py-3 text-left max-w-[500px] w-full'>
        <div className='pb-4'>
            <img src={trophy} alt="trophy" />
        </div>
        <div className='pb-4'>
            <h1 className='text-2xl font-bold'>Congartulations!</h1>
        </div>
        <div className='pb-2'>
            <p className='text-slate-600 text-sm'>Your account has been created successfully.</p>
        </div>
        <div className='pb-6'>
            <p className='text-slate-600 text-sm'>Thank you for signing up with Naviscom Cloud Services. A confirmation email has been sent to your inbox.</p>
        </div>
        <button className='w-full text-sm bg-black text-white py-3 rounded-full' onClick={toDashboard}>Proceed</button>

      </div>
    </div>
    </VerificationCommon>
    </>
  )
}

export default FinalEntrance
