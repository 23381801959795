import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SettingProfile from './SettingComponents/SettingProfile/SettingProfile'
import SettingSecurity from './SettingComponents/SettingSecurity/SettingSecurity'
import SettingPayInfo from './SettingComponents/SettingPayInfo/SettingPayInfo'
import AddSubUser from './SettingComponents/SettingSubUser/AddSubUser/AddSubUser'
import SubUsers from './SettingComponents/SettingSubUser/SubUsers/SubUsers'
import SubUserSetting from './SettingComponents/SettingSubUser/SubUserSetting/SubUserSetting'

function SettingRoutes() {
  return (
    <div>
      <Routes>
        <Route path='profilesetting' element={<SettingProfile/>} />
        <Route path='security' element={<SettingSecurity/>} />
        <Route path='payinfo' element={<SettingPayInfo/>} />
        <Route path='add-sub-user' element={<AddSubUser/>} />
        <Route path='sub-users' element={<SubUsers/>} />
        <Route path='user-settings/:subUserId' element={<SubUserSetting/>} />
      </Routes>
    </div>
  )
}

export default SettingRoutes
