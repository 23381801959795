import React from 'react'
import { Route, Routes } from 'react-router-dom'
import FAQS from './FAQs/FAQS'
import Support from './Support/Support'

function ContactUsRouting() {
  return (
    <div>
      <Routes>
        <Route path='/faqs' element={<FAQS/>} />
        <Route path='/support' element={<Support/>} />
      </Routes>
    </div>
  )
}

export default ContactUsRouting
