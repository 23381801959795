import React from 'react'
import Navbar from '../CommonComponents/Navbar/Navbar'
import { Icon } from '@iconify/react/dist/iconify.js'
import frame from "../../Assets/Images/Dashboard/Frame.png"
import catalyst from "../../Assets/Images/Dashboard/Catalyst.png"
import financer from "../../Assets/Images/Dashboard/Financer.png"
import management from "../../Assets/Images/Dashboard/Management.png"
import top_bg from "../../Assets/Images/Rectangle7.png"
import bottom_right_bg from "../../Assets/Images/Frame 201.png"
import bottom_middle_bg from "../../Assets/Images/Isolation_Mode.png"
import { useNavigate } from 'react-router-dom'
import LeftSettings from '../CommonComponents/LeftSettings/LeftSettings'
import { ApiState } from '../../../Context/ApiProvider'
import useAxios from '../../../utils/axiosInstance'

const Main = () => {
   const navigate = useNavigate()
   const axiosInstance = useAxios()
   const {token} = ApiState()
   function toApiJourny () {
      navigate('projects')
   }
   async function getAllusers (){
      const response = await axiosInstance.get(`${process.env.REACT_APP_SERVER_URL}/users?page_id=1&page_size=5`,{
         headers:{
            authorization: `Bearer ${token}`
         }
      })

      console.log(response.data);

   }
  return (
   <LeftSettings>
    <div className='bg-[#F9FBFC] '>
    <Navbar/>
    <div className='lg:pt-8 pb-20 w-full'>
     <div className='lg:px-[5%] grid grid-cols-1 lg:grid-cols-6 gap-5 pb-12'>
      <div className='col-span-1 lg:col-span-4'>
        <div className=' bg-[#294DD1] py-10 px-[5%] h-full lg:rounded-xl relative overflow-hidden'>
        <div className='absolute -top-44 -left-64 z-0 h-full'>
        <img className='h-fit' src={top_bg} alt="top_bg" />
        </div>
        <div className='absolute -bottom-12 right-5'>
        <img className='w-28' src={bottom_right_bg} alt="bottom_right_bg" />
        </div>
        <div className='absolute bottom-0 right-56'>
        <img className='w-36' src={bottom_middle_bg} alt="bottom_middle_bg" />
        </div>
          <h1 className='relative text-2xl lg:text-3xl text-white font-semibold pb-2 z-10'>Greetings, Ahmad!</h1>
          <p className='relative text-white text-sm lg:text-xl font-light z-10'>Welcome to the Naviscom cloud, Lets get started!</p>
        </div>

      </div>
      <div className='px-[5%] lg:px-0 col-span-1 lg:col-span-2'>
        <div className='bg-white py-4 px-6 rounded-xl border border-slate-200'>
         <div className='pb-6'>
            <h1 className='2xl:text-2xl text-lg font-bold pb-2'>We Would Love To Hear From You!</h1>
            <p className='text-slate-500 text-sm 2xl:text-base'>Your suggestions and feedback help us improve.</p>
         </div>
         <button className='bg-black text-white text-sm py-1 px-6 rounded-full'>Feedback</button>
        </div>
      </div>
     </div>

     <div className='flex justify-between items-center pb-6 px-[5%]'>
        <div>
            <h1 className='text-2xl font-bold'>Product And Solutions</h1>
        </div>
        <div className='relative hidden lg:block'>
        <input
        type='text'
        placeholder='Search'
        className='border border-slate-400 p-2 pl-10 w-full rounded-full'
      />
      <button
        type='button'
        className='absolute inset-y-0 left-0 flex items-center pl-3'
      >
        <Icon icon="ri:search-line" width="24" height="24" className='text-slate-500' />
      </button>
      </div>
     </div>

     <div className='px-[5%] grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-6'>
        <div className='col-span-1 bg-[#EFF4FD] px-4 py-4 rounded-2xl border border-slate-200'>
         <h1 className='py-1 px-4 text-black bg-yellow-400 text-sm rounded-lg w-fit font-semibold'>Most Popular</h1>
         <div className='flex gap-2 py-4'>
            <div className='max-w-[300px]'>
             <h1 className='text-xl font-bold pb-2'>API CraftPro</h1>
             <p className='text-xs'>Quickly generate API structures and push complete files to GitHub, saving time and streamlining your workflow.</p>
            </div>
            <div className='w-full'>
             <img className='w-24 h-24' src={frame} alt="frame" />
            </div>
         </div>
         <button className='bg-black text-white py-2 px-10 rounded-full text-xs' onClick={toApiJourny}>Explore</button>
        </div>
        <div className='col-span-1 bg-[#EFF4FD] px-4 py-4 rounded-2xl border border-slate-200'>
         <h1 className='py-1 px-4 text-black bg-yellow-400 text-sm rounded-lg w-fit font-semibold'>Most Popular</h1>
         <div className='flex gap-2 py-4'>
            <div className='max-w-[300px]'>
             <h1 className='text-xl font-bold pb-2'>Catalyst</h1>
             <p className='text-xs'>Catalyst helps CSPs optimize network investments by analyzing profitability, enabling smarter CAPEX decisions.</p>
            </div>
            <div className='w-full'>
             <img className='w-24 h-24' src={catalyst} alt="frame" />
            </div>
         </div>
         <button className='bg-black text-white py-2 px-10 rounded-full text-xs'>Explore</button>
        </div>
        <div className='col-span-1 bg-[#EFF4FD] px-4 py-4 rounded-2xl border border-slate-200'>
         <h1 className='py-1 px-4 text-black bg-cyan-400 text-sm rounded-lg w-fit font-semibold'>New</h1>
         <div className='flex gap-2 py-4'>
            <div className='max-w-[300px]'>
             <h1 className='text-xl font-bold pb-2'>Financer</h1>
             <p className='text-xs'>Simplify complex financial processes with our intuitive SaaS solution—automating everything from invoicing to reconciliation.</p>
            </div>
            <div className='w-full'>
             <img className='w-24 h-24' src={financer} alt="frame" />
            </div>
         </div>
         <button className='bg-black text-white py-2 px-10 rounded-full text-xs'>Explore</button>
        </div>

        <div className='col-span-1 lg:col-span-3 hidden lg:block bg-[#EFF4FD] px-6 py-6 rounded-3xl border border-slate-200'>
         <h1 className='py-1 px-4 text-black bg-cyan-400 text-sm rounded-lg w-fit font-semibold'>New</h1>
         <div className='flex justify-between gap-4 py-4 items-center'>
            <div className='w-[65%]'>
             <h1 className='text-xl font-bold pb-2'>Resource Access Management</h1>
             <p className='text-xs'>Efficiently manage and control access to your resources with our comprehensive Resource Access Management service. Create multiple sub-users within your organization and assign specific roles and permissions tailored to their needs.</p>
            </div>
            <div>
             <img className='w-44' src={management} alt="" />
            </div>
         </div>
         <button className='bg-black text-white py-2 px-10 rounded-full text-xs'>Explore</button>
        </div>
     </div>
    </div>
    </div>
   </LeftSettings>
  )
}

export default Main
