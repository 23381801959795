import React, { useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js';
import VerificationCommon from '../../Entrance/Common Components/Verification Common/VerificationCommon';
import { useNavigate } from 'react-router-dom';

const ResetPass = () => {
    const [newPassword, setNewPassword] = useState(false);
    const [confirmPass, setConfirmPass] = useState(false);
    const navigate = useNavigate()
    function toNext () {
      navigate('/forgetpassword/passwordchanged')
    }


  const handleTogglePassword = () => {
    setNewPassword(!newPassword);
  };
  const handleConfirmPassword = () => {
    setConfirmPass(!confirmPass)
  };
  return (
    <>
    <VerificationCommon>
    <div className='h-full flex justify-center items-center'>
      <div className='px-3 py-3 text-left max-w-[500px] w-full'>
        <div className='pb-4'>
            <button className='py-2 pl-3 pr-1 bg-slate-100 rounded-full'>
            <Icon icon="material-symbols-light:arrow-back-ios" width="24" height="24"  style={{color: "#294DD1"}} />
            </button>
        </div>
        <div className='pb-6'>
            <h1 className='text-2xl font-bold'>Reset Your Password</h1>
        </div>

        <div className='pb-4'>
        <label htmlFor='new pass' className='font-semibold text-sm pb-1'>Enter New Password</label>
        <div className='relative'>
         <input
          type={newPassword ? 'text' : 'password'}
          id='new pass'
          placeholder='Enter your password'
          className='border border-slate-300 p-2 w-full rounded-lg'
         />
         <button
         type='button'
         onClick={handleTogglePassword}
         className='absolute inset-y-0 right-0 flex items-center pr-3'
         >
        <Icon icon={newPassword ? "iconamoon:eye-off-bold" : "lucide:eye"} width="24" height="24" style={{ color: '#294DD1'}}  />
        </button>
        </div>
        </div>

        <div className='pb-8'>
        <label htmlFor='re-enter' className='font-semibold text-sm pb-1'>Re-enter New Password</label>
        <div className='relative'>
         <input
          type={confirmPass ? 'text' : 'password'}
          id='re-enter'
          placeholder='Re-enter your password'
          className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
         />
         <button
          type='button'
          onClick={handleConfirmPassword}
          className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
         >
         <Icon icon={confirmPass ? "iconamoon:eye-off-bold" : "lucide:eye"} width="24" height="24" style={{ color: '#294DD1'}}  />
         </button>
         </div>
        </div>

        <button className='w-full bg-black text-white py-3 rounded-full text-sm' onClick={toNext}>Submit</button>
      </div>
    </div>
    </VerificationCommon>
    </>
  )
}

export default ResetPass
