import React from 'react'
import logo from "../../../Assets/Images/NaviscomLogoWhite.png"
import top_bg from "../../../Assets/Images/Rectangle7.png"
import bottom_right_bg from "../../../Assets/Images/Frame 201.png"
import bottom_middle_bg from "../../../Assets/Images/Isolation_Mode.png"
import './VerificationCommon.css'
import VerifyEmail from '../../Modals/VerifyEmail'
import VerifyPhone from '../../Modals/VerifyPhone'
import { useNavigate } from 'react-router-dom'

const VerificationCommon = ({children, verifyPage, setVerifyPage, verifyPhone, setVerifyPhone, signupBtn, loginBtn}) => {
  const navigate = useNavigate();

  return (
    <div>
     {verifyPage && (
    <>
      {/* Background overlay with opacity */}
      <div onClick={()=> setVerifyPage(false)} className='fixed top-0 bottom-0 left-0 right-0 bg-[#F5F5F5] opacity-65 z-10'></div>

      {/* Modal content */}
      <div className='fixed top-[50%] z-20 left-[50%] translate-x-[-50%] translate-y-[-50%]'>
        <VerifyEmail verifyPage={verifyPage} setVerifyPage={setVerifyPage} />
      </div>
    </>
  )}
     {verifyPhone && (
    <>
      {/* Background overlay with opacity */}
      <div onClick={()=> setVerifyPhone(false)} className='fixed top-0 bottom-0 left-0 right-0 bg-black opacity-40 z-10'></div>

      {/* Modal content */}
      <div className='fixed top-[50%] z-10 left-[50%] translate-x-[-50%] translate-y-[-50%]'>
        <VerifyPhone verifyPhone={verifyPhone} setVerifyPhone={setVerifyPhone} />
      </div>
    </>
  )}
    <div className='flex flex-col lg:overflow-hidden lg:flex-row'>
      <div style={{backgroundColor: "#294DD1"}} className='flex flex-row items-center justify-between lg:items-start lg:justify-normal lg:flex-col overflow-hidden lg:min-h-screen md:overflow-visible pl-[3%] relative  pr-[5%]'>

        <div className='absolute hidden lg:block top-0 right-0 z-0 h-full'>
        <img className='h-fit' src={top_bg} alt="top_bg" />
        </div>
        <div className='absolute hidden lg:block -bottom-3 right-10'>
        <img className='w-24' src={bottom_right_bg} alt="bottom_right_bg" />
        </div>
        <div className='absolute hidden lg:block bottom-0 right-45'>
        <img className='w-40' src={bottom_middle_bg} alt="bottom_middle_bg" />
        </div>

        <div className='py-3 lg:pt-3 lg:w-fit z-10'>
          <img className='w-40 lg:w-full' src={logo} alt="logo" />
        </div>
        <div className='lg:hidden'>
          {signupBtn === 'signup' && (
            <button onClick={()=> navigate('/signup')} className='text-white text-sm'>Sign Up</button>
          )}
          {loginBtn === 'login' && (
            <button onClick={()=> navigate('/')} className='text-white text-sm'>Login</button>
          )}
        </div>
        <div className='flex-grow hidden lg:flex justify-center items-center pr-10 max-w-[400px] py-6 md:py-0 lg:w-[450px] text-left'>
          <h1 className="text-5xl font-light text-white pl-[5%] pb-5 z-10">
          Welcome to Naviscom Services, your partner in innovative tech solutions.
          </h1>
        </div>
      </div>
      <div className='w-full pt-10'>
        {children}
      </div>
    </div>
    </div>
  )
}

export default VerificationCommon
