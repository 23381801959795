import React, { useEffect, useState } from 'react'
import profileImg from '../../../../Assets/Images/hand-img.jpg'
import { Icon } from '@iconify/react/dist/iconify.js'
import SettingSideBar from '../../../SettingSideBar/SettingSideBar'
import Navbar from '../../../../Dashboard/CommonComponents/Navbar/Navbar'
import ApiCraftProImg from '../../../../Assets/Images/Dashboard/Frame.png'
import CatalystImg from '../../../../Assets/Images/Dashboard/Catalyst.png'
import FinancerImg from '../../../../Assets/Images/Dashboard/Financer.png'
import './SubUserSetting.css'
import { useLocation, useParams } from 'react-router-dom'
import useAxios from '../../../../../utils/axiosInstance'
import { ApiState } from '../../../../../Context/ApiProvider'
import axios from 'axios'

const SubUserSetting = () => {
  const { token } = ApiState()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordEdit, setPasswordEdit] = useState(false)
  const [applicationsData, setApplicationsData] = useState([])
  const [userPrivileges, setUserPrivileges] = useState([]) // Holds the privileges of the user
  const { subUserId } = useParams()
  const axiosInstance = useAxios()
  const location = useLocation()
  const user = location.state?.user

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)

  const FetchApplicationsData = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_SERVER_URL}/applications`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      setApplicationsData(response.data)
    } catch (error) {
      console.log(error)
      setApplicationsData([])
    }
  }

  const FetchUserPrivileges = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_SERVER_URL}/subuserprivileges1/${subUserId}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      console.log('response in fetch user',response.data)
      setUserPrivileges(response.data) // Save user's current privileges
    } catch (error) {
      console.log(error)
    }
  }
console.log('userPrivileges',subUserId)
  const addPrivilegeToSubUser = async (appId) => {
    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_SERVER_URL}/subuserprivileges`,
        {
          subuser_id:subUserId,
          app_id: appId,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      ).then((res) => {
        setUserPrivileges((prev) => [...prev, res.data])
        console.log("adding privilages",res)
        console.log('allpriviliges',applicationsData)
      })

     // Update local state
    } catch (error) {
      console.log(error)
    }
  }

  const removePrivilegeFromSubUser = async (appId) => {
    console.log('userId',subUserId)
    try {
      await axiosInstance.delete(
        `${process.env.REACT_APP_SERVER_URL}/deletesubuserprivileges/${subUserId}/${appId}`,

        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      ).then((res) => {
        console.log("removing preveleges",res)
        setUserPrivileges((prev) => prev.filter((privilege) => privilege.app_id !== appId));      })


    } catch (error) {
      console.log(error)
    }
  }

  const handlePrivilege = async (e, appId) => {
    e.stopPropagation()
    if (userPrivileges.some(privilege => privilege.app_id === appId)) {
      await removePrivilegeFromSubUser(appId)
    } else {
      await addPrivilegeToSubUser(appId)
    }
  }

  useEffect(() => {
    FetchApplicationsData()
    FetchUserPrivileges()
  }, [])

  const Done = async () => {
    if (!oldPassword || !newPassword) {
      alert('please fill all details')
      return
    }
    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_SERVER_URL}/updatesubusers`,
        {
          subuser_name: user.first_name,
          old_password: oldPassword,
          new_password: newPassword,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      setPasswordEdit(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="min-h-screen bg-[#f5f5f5]">
      <div><Navbar /></div>
      <div className="flex bg-[#f5f5f5] justify-start gap-[15%] w-full p-10 ">
        <div><SettingSideBar /></div>
        <div className="w-[700px] py-8 px-[100px] flex justify-center items-center rounded-3xl bg-white border border-[#CECECE] ">
          <div className="flex flex-col gap-5 items-start w-full">
            <div><h1 className="text-2xl font-bold">User Settings</h1></div>
            <div className="w-full">
              <div className="p-2 bg-blue-50 rounded-xl"><h1 className="font-bold">App Access</h1></div>
              <div className="flex flex-col gap-5 w-full py-7">
                {applicationsData.map((item) => (
                  <div key={item.id} className="flex justify-between items-center w-full">
                    <div className="flex gap-4 items-center">
                      <img className="w-16 h-16 rounded-full" src={FinancerImg} alt="FinancerImg" />
                      <div>
                        <h1 className="font-semibold">{item.app_name}</h1>
                        <p className="text-sm text-[#767676]">{item.app_desc}</p>
                      </div>
                    </div>
                    <div className="pl-2">
                      <div
                        className={userPrivileges.some(privilege => privilege.app_id === item.id) ? 'toggle-btn-container1' : 'toggle-btn-container'}
                        onClick={(e) => handlePrivilege(e, item.id)}
                        >
                        <div className={userPrivileges.some(privilege => privilege.app_id === item.id) ? 'toggle-btn-element1' : 'toggle-btn-element'}>
                          {userPrivileges.some(privilege => privilege.app_id === item.id) ? <Icon icon="hugeicons:tick-02" /> : <></>}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="sp-bs-header">
                <div><h3>Password</h3></div>
                {passwordEdit ? (
                  <div className="sp-cancel-sace">
                    <div className="sp-cs-btn" onClick={Done}>Done</div>
                  </div>
                ) : (
                  <div className="sp-bs-h-edit" onClick={() => setPasswordEdit(true)}><Icon icon="iconamoon:edit-light" />Edit</div>
                )}
              </div>
              {passwordEdit ? (
                <div className="password-input-container">
                  <label>Password</label>
                  <input value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} type="password" placeholder="Old Password" />
                  <label className="pt-2">New Password</label>
                  <input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" placeholder="New Password" />
                </div>
              ) : (
                <div className="sp-table">
                  <table>
                    <tr>
                      <td className="table-data-1">Password</td>
                      <td className="able-data-2">{'*******'}</td>
                    </tr>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubUserSetting
