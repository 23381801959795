import React, { useState } from 'react'
import SettingSideBar from './../../SettingSideBar/SettingSideBar'
import { Icon } from '@iconify/react/dist/iconify.js'
import './settingsecurity.css'
import Navbar from '../../../Dashboard/CommonComponents/Navbar/Navbar'

function SettingSecurity() {
  const [passwordEdit,setPasswordEdit] = useState(false)
  const [toggle,setToggle] = useState(false)
  function Done () {
    setPasswordEdit(!passwordEdit)
  }
  function Edit () {
    setPasswordEdit(!passwordEdit)
  }
  return (
    <div className='security-page'>
      <div><Navbar/></div>
      <div className='security-body'>
        <div className='security-sidebar'>
          <SettingSideBar activeState={'security'} />
        </div>
        <div className='security-body-outer'>
          <div className='security-body-inner'>
            <div><h1>Security</h1></div>
            
            <div className='sp-bs-header'>
              <div><h3>Password</h3></div>
              {
                passwordEdit ? <div className='sp-cancel-sace'>
                  <div className='sp-cs-btn' onClick={Done}>Done</div>
                </div> : 
                <div className='sp-bs-h-edit' onClick={Edit}><Icon icon="iconamoon:edit-light" />Edit</div>
              }
            </div>
            {
              passwordEdit ? <div className='password-input-container'>
              <label>Password</label>
              <input type='password' placeholder='New Password' />
            </div> : 
            <div className='sp-table'>
            <table>
              <tr>
                <td className='table-data-1'>Password</td>
                <td className='able-data-2'>{'*******'}</td>
              </tr>
            </table>
          </div>
            }
            <div className='sp-bs-header'>
              <div><h3>Two Factor Authentication</h3></div>
            </div>
            <div className='authentication-section'>
              <div className='authentication-text'>
                <div className='text-first'>Two Factor Authentication</div>
                <div className='text-second'>Turn on two factor authentication while login to add another layer of security.</div>
              </div>
              <div>
                <div className={toggle ? 'toggle-btn-container1' : 'toggle-btn-container'} onClick={()=>setToggle(!toggle)} >
                  <div className={toggle ? 'toggle-btn-element1' : 'toggle-btn-element'}>
                    {
                      toggle ? <Icon icon="hugeicons:tick-02" /> : <></>
                    }
                  
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingSecurity
