import React from 'react'
import { Route, Routes } from 'react-router-dom'
import VerificationCommon from '../Entrance/Common Components/Verification Common/VerificationCommon'
import ForgetPage from './ForgetPage/ForgetPage'
import ResetPass from './ResetPassword/ResetPass'
import PasswordChanged from './PasswordChanged/PasswordChanged'

function ForgetPasswordRoutes() {
  return (
    <div>
      <Routes>
        <Route path='forgetpassword' element={
                <ForgetPage/>
        } />
        <Route path='resetpassword' element={
                <ResetPass/>
        }/>
        <Route path='passwordchanged' element={
                <PasswordChanged/>
        } />
      </Routes>
    </div>
  )
}

export default ForgetPasswordRoutes
