import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import Navbar from "../CommonComponents/Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const CreateApi = () => {
  const [inputValue, setInputValue] = useState("");
  const [generalToggle, setGeneralToggle] = useState(true)
  const [dbToggle, setDbToggle] = useState(true)
  const [githubToggle, setGithubToggle] = useState(true)
  const [deployToggle, setDeployToggle] = useState(true)
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate()

  function toSuccess () {
    navigate('/dashboard/apicrafted')
  }

  return (
    <div>
    <Navbar/>
    <div className="flex px-[5%] pt-14 pb-[8%] bg-[#F5F5F5]">
      <div className="px-4 hidden lg:block">
        <button className="py-2 pl-3 pr-1 bg-slate-100 rounded-full">
          <Icon
            icon="material-symbols-light:arrow-back-ios"
            width="24"
            height="24"
            style={{ color: "#294DD1" }}
          />
        </button>
      </div>

      <div className="w-full flex justify-center items-center">
        <div className="lg:bg-white py-[5%] lg:px-[10%] lg:border border-slate-200 rounded-2xl">
          <div className="lg:px-3 py-3 text-left w-full max-w-[500px] md:w-[500px]">
            <div className="pb-8">
              <h1 className="text-3xl font-bold">Create New API</h1>
            </div>
            <div className="relative py-3 px-3 bg-blue-50 rounded-lg mb-4">
              <div className="font-bold">General</div>
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => setGeneralToggle(!generalToggle)}
              >
                <Icon
                  icon={generalToggle ? "iconamoon:arrow-up-2-light" : "iconamoon:arrow-down-2-light"}
                  width="24"
                  height="24"
                  className="text-[blue]"
                />
              </button>
            </div>
            {generalToggle ? (

            <div className="pb-4">
              <label htmlFor="project name" className="font-semibold pb-1 text-xs">
                Project Name <span className="text-red-500 text-sm">*</span>
              </label>
              <input
                className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
                type="text"
                id="project name"
                placeholder="John Doe"
              />
            </div>
            ):(
                <div className="hidden">

                </div>
            )}
          <div className="relative py-3 px-3 bg-blue-50 rounded-lg mb-4">
            <div className="font-bold">Database Details</div>
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
              onClick={() => setDbToggle(!dbToggle)}
            >
              <Icon
                  icon={dbToggle ? "iconamoon:arrow-up-2-light" : "iconamoon:arrow-down-2-light"}
                  width="24"
                height="24"
                className="text-[blue]"
              />
            </button>
          </div>
          {dbToggle ? (
          <div>

          <div className="pb-4">
              <label htmlFor="database name" className="font-semibold text-xs pb-1">
                Database Name <span className="text-red-500 text-sm">*</span>
              </label>
              <input
                className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
                type="text"
                id="database name"
                placeholder="Database Name"
              />
            </div>

          <div className="flex flex-col lg:flex-row w-full gap-3 pb-4">
            <div className="w-full">
              <label htmlFor="database engine" className="font-semibold text-xs pb-1">
                Database Engine {" "}
                <span className="text-red-500 text-sm">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="database engine"
                  placeholder="Select Engine"
                  className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <Icon
                    icon="iconamoon:arrow-down-2-light"
                    width="24"
                    height="24"
                    className="text-slate-600"
                  />
                </button>
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="engine ver" className="font-semibold text-xs pb-1">
                Database Engine Version  {" "}
                <span className="text-red-500 text-sm">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="engine ver"
                  placeholder="Select Version"
                  className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <Icon
                    icon="iconamoon:arrow-down-2-light"
                    width="24"
                    height="24"
                    className="text-slate-600"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="pb-4">
              <label htmlFor="db user name" className="font-semibold text-xs pb-1">
                Database User Name <span className="text-red-500 text-sm">*</span>
              </label>
              <input
                className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
                type="text"
                id="db user name"
                placeholder="project_project"
              />
            </div>

            <div className="pb-4">
          <label htmlFor="db pass" className="font-semibold text-xs pb-1">
            Password <span className="text-red-500 text-sm">*</span>
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="db pass"
              placeholder="Enter your password"
              className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
            />
            <button
              type="button"
              onClick={handleTogglePassword}
              className="absolute inset-y-0 right-0 flex items-center  pr-3"
            >
              <Icon icon={showPassword ? "iconamoon:eye-off-bold" : "lucide:eye"} width="24" height="24"  style={{color: "#294DD1"}} />

            </button>
          </div>
        </div>
          </div>
          ):(
            <div className="hidden"></div>
          )}
          
          <div className="relative py-3 px-3 bg-blue-50 rounded-lg mb-4">
            <div className="font-bold">Github Details</div>
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
              onClick={() => setGithubToggle(!githubToggle)}
            >
              <Icon
                  icon={githubToggle ? "iconamoon:arrow-up-2-light" : "iconamoon:arrow-down-2-light"}
                  width="24"
                height="24"
                className="text-[blue]"
              />
            </button>
          </div>
          {githubToggle ? (
          <div className='flex flex-col lg:flex-row w-full gap-3 pb-4'>
            <div className='w-full'>
            <label htmlFor="github name" className="font-semibold text-xs pb-1">Github Acccount Name <span className="text-red-500 text-sm">*</span></label>
            <input type="text" id="github name" className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none" placeholder='John Doe'/>
            </div>
            <div className='w-full'>
            <label htmlFor="github acc" className="font-semibold text-xs pb-1">Github Acccount PAT <span className="text-red-500 text-sm">*</span></label>
            <input type="text" id="github acc" className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none" placeholder='John Doe'/>
            </div>
        </div>
          ):(
            <div className="hidden"></div>
          )}
          
        <div className="relative py-3 px-3 bg-blue-50 rounded-lg mb-4">
            <div className="font-bold">Deployment & Authentication Strategy</div>
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
              onClick={() => setDeployToggle(!deployToggle)}
            >
              <Icon
                  icon={deployToggle ? "iconamoon:arrow-up-2-light" : "iconamoon:arrow-down-2-light"}
                  width="24"
                height="24"
                className="text-[blue]"
              />
            </button>
        </div>
        {deployToggle ? (
        <div className="flex flex-col lg:flex-row w-full gap-3 pb-4">
            <div className="w-full">
              <label htmlFor="deploy auth" className="font-semibold text-xs pb-1">
                Deployment Authentication {" "}
                <span className="text-red-500 text-sm">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="deploy auth"
                  placeholder="Select"
                  className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <Icon
                    icon="iconamoon:arrow-down-2-light"
                    width="24"
                    height="24"
                    className="text-slate-600"
                  />
                </button>
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="auth strategy" className="font-semibold text-xs pb-1">
                Authentication Strategy {" "}
                <span className="text-red-500 text-sm">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="auth strategy"
                  placeholder="Select"
                  className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  <Icon
                    icon="iconamoon:arrow-down-2-light"
                    width="24"
                    height="24"
                    className="text-slate-600"
                  />
                </button>
              </div>
            </div>
          </div>
        ):(
            <div className="hidden"></div>
        )}

<div className="relative py-3 px-3 bg-blue-50 rounded-lg mb-4">
            <div className="font-bold">AWS Credentials</div>
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
              onClick={() => setDeployToggle(!deployToggle)}
            >
              <Icon
                  icon={deployToggle ? "iconamoon:arrow-up-2-light" : "iconamoon:arrow-down-2-light"}
                  width="24"
                height="24"
                className="text-[blue]"
              />
            </button>
        </div>
        {deployToggle ? (
        <>
        <div className="pb-4">
        <label htmlFor="pr name" className="font-semibold pb-1 text-xs">
          Project Name <span className="text-red-500 text-sm">*</span>
        </label>
        <input
          className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
          type="text"
          id="pr name"
          placeholder="John Doe"
        />
      </div>
      <div className='pb-4'>
      <label htmlFor="industry" className="font-semibold pb-1 text-xs" >Industry <span className='text-red-500 text-sm'>*</span></label>
      <div className='relative'>
        <input
          type='text'
          id="industry"
          placeholder='Select Your Industry'
          className="border border-slate-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50  focus:border-blue-700 focus:outline-none"
        />
        <button
          type='button'
          className='absolute inset-y-0 right-0 flex items-center pr-3'
        >
          <Icon icon='iconamoon:arrow-down-2-light' width="24" height="24" className='text-slate-600'  />
        </button>
      </div>  
      </div>
      <div className="flex gap-2 items-center">
        <input type="checkbox" />
        <p className="text-sm">Create project wothout AWS Cluster</p>
      </div>
        </>
        ):(
            <div className="hidden"></div>
        )}

        <div className="flex gap-4 lg:gap-8 pt-6">
        <button className="w-full bg-black text-xs lg:text-base text-white py-3 rounded-full">Create AWS Cluster</button>
        <button className="w-full bg-black text-xs lg:text-base text-white py-3 rounded-full" onClick={toSuccess}>Create Project</button>

        </div>

          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CreateApi;
