import React, { useState } from 'react';
import logo from "../../../Assets/Images/Group22.png";
import logoResponsive from "../../../Assets/Images/NaviscomLogoWhite.png";
import profileImg from "../../../Assets/Images/hand-img.jpg";
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { ApiState } from '../../../../Context/ApiProvider';

const Navbar = () => {
  const {user} = ApiState()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  
  function toDashboard() {
    navigate('/dashboard');
  }

  function toFAQs() {
    navigate('/contactus/faqs');
  }

  function toProfile() {
    navigate('/setting/profilesetting');
  }

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  return (
    <div className='relative'>
      {/* Navbar */}
      <div className='px-[5%] py-2 border-b border-slate-400 bg-[#294DD1] lg:bg-[#F5F5F5]'>
        <div className='hidden lg:flex justify-between py-1 items-center'>
          <div onClick={toDashboard}>
            <img src={logo} alt="logo" />
          </div>
          <div className='flex items-center gap-4'>
            <div className='text-slate-500 text-sm'>Notifications</div>
            <div className='text-slate-500 text-sm' onClick={toFAQs}>FAQs</div>
            <div className='flex gap-2 items-center'>
              <div className=''>
                <img className='w-10 h-10 rounded-full' src={profileImg} alt="img" />
              </div>
              <div onClick={toProfile}>
                <h1 className='text-sm'>{user.first_name}</h1>
                <p className='text-xs text-slate-500 font-semibold'>{user.email}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Navbar */}
        <div className='flex lg:hidden justify-between py-1 items-center'>
          <div onClick={toDashboard}>
            <img className='w-40 lg:w-full' src={logoResponsive} alt="logo" />
          </div>
          <div className='flex gap-3'>
            <div>
              <Icon icon="mi:notification" width={24} height={24} style={{ color: "white" }} />
            </div>
            <div>
              <Icon icon="ri:search-line" width={24} height={24} style={{ color: "white" }} />
            </div>
            <div onClick={toggleSidebar}>
              {/* Toggle icon: change between three-bars and cross */}
              <Icon 
                icon={isSidebarOpen ? "akar-icons:cross" : "octicon:three-bars-16"} 
                width={24} 
                height={24} 
                style={{ color: "white" }} 
              />
            </div>
          </div>
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 right-0 w-64 bg-white z-50 transition-transform transform ${
          isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
        }`}>
        <div className="pl-4 py-8 bg-[#294DD1] text-white">
          <button onClick={toggleSidebar} className='absolute top-4 right-6'>
            <Icon icon="akar-icons:cross" width={24} height={24} />
          </button>
          <div>
          <img src={profileImg} className='w-16 h-16 rounded-full' alt="profileimage" />
          <h2 className="font-semibold pt-4">{user.first_name}</h2>
          <p className='text-xs'>{user.email}</p>
          </div>
        </div>
        <ul>
          <li className="py-4 px-4 text-sm border-b border-slate-500" onClick={()=> navigate("/setting/profilesetting")}>Settings</li>
          <li className="py-4 px-4 text-sm border-b border-slate-500" onClick={()=> navigate("/contactus/support")}>Support</li>
          <li className="py-4 px-4 text-red-500 text-sm border-b border-slate-500" onClick={()=> navigate("/")}>Logout</li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
