import React from 'react'
import Navbar from '../CommonComponents/Navbar/Navbar'
import top_bg from "../../Assets/Images/Rectangle7.png"
import bottom_right from "../../Assets/Images/Frame 201.png" 
import bottom_middle_bg from "../../Assets/Images/Isolation_Mode.png"
import { Icon } from '@iconify/react/dist/iconify.js'
import Chart from './Chart'
import handImg from "../../Assets/Images/hand-img.jpg"
import { useNavigate } from 'react-router-dom'

const ChartPage = () => {
  const navigate = useNavigate()
  function toCreateProject () {
    navigate('/dashboard/createapi')
  }
  return (
    <div className='bg-[#F5F5F5]'>
      <Navbar/>
      <div className='lg:px-[3%] lg:py-12'>
        <div className='flex flex-col gap-4'>
        <div className='w-full bg-[#294DD1] py-10 px-[5%] lg:px-[3%] lg:py-[3%] lg:rounded-xl relative overflow-hidden'>
        <div className='absolute -top-44 -left-48 z-0 h-full'>
        <img className='h-fit' src={top_bg} alt="top_bg" />
        </div>
        <div className='absolute -bottom-8 right-10'>
        </div>
        <div className='absolute -bottom-10 right-[40%]'>
        <img src={bottom_middle_bg} className='w-48' alt="bottom_middle_bg" />
        </div>
        <div className='absolute -bottom-14 right-[20%]'>
        <img src={bottom_right} className='w-28' alt="bottom_right_bg" />
        </div>
        <div className='flex flex-col lg:flex-row justify-between relative'>
          <div className='max-w-[550px]'>
          <h1 className='relative text-xl lg:text-3xl text-white font-semibold z-10 pb-3'>Get started with API CreftPro</h1>
          <p className='relative text-xs lg:text-base text-white z-10 pb-5'>Quickly generate API structures and push complete files to GitHub, saving time and streamlining your workflow.</p>
          <button className='bg-white hidden lg:block relative z-10 text-black py-2 text-sm px-12 rounded-full font-semibold' onClick={toCreateProject}>Create New Project</button>
          </div>
          <div className='py-6 px-6 bg-black bg-opacity-15 rounded-xl'>
            <div className='flex gap-2 items-center pb-2'>
            <Icon icon="mdi:tick-circle" width="1.2em" height="1.2em"  style={{color: "white"}} />
            <p className='text-white text-xs lg:text-sm'>Create APIs with minimal effort.</p>
            </div>
            <div className='flex gap-2 items-center pb-2'>
            <Icon icon="mdi:tick-circle" width="1.2em" height="1.2em"  style={{color: "white"}} />
            <p className='text-white text-xs lg:text-sm'>Push API files directly to GitHub.</p>
            </div>
            <div className='flex gap-2 items-center pb-2'>
            <Icon icon="mdi:tick-circle" width="1.2em" height="1.2em"  style={{color: "white"}} />
            <p className='text-white text-xs lg:text-sm'>Intuitive interface for smooth API management.</p>
            </div>
            <div className='flex gap-2 items-center'>
            <Icon icon="mdi:tick-circle" width="1.2em" height="1.2em"  style={{color: "white"}} />
            <p className='text-white text-xs lg:text-sm'>Use ready-made templates for consistency.</p>
            </div>
          </div>
          <button className='bg-white block lg:hidden relative z-10 text-black py-2 mt-8 text-sm w-full rounded-full font-semibold' onClick={toCreateProject}>Create New Project</button>

          </div>
        </div>

      <div className='w-full px-[5%] lg:px-0 flex flex-col lg:flex-row gap-4'>
        <div className='w-full lg:w-1/2 max-h-[450px] overflow-y-hidden'>
         <Chart/>
        </div>

        <div className='w-full lg:w-1/2 max-h-[450px]'>
  <div className='w-full py-8 bg-white border border-slate-200 rounded-2xl max-h-[450px] flex flex-col'>
    <div className='flex justify-between items-center pb-6 px-[5%]'>
      <div><h1 className='text-xl font-bold'>Projects History</h1></div>
      <div className='relative hidden lg:block'>
        <input
          type='text'
          placeholder='Search'
          className='border border-slate-400 p-2 pl-10 w-full rounded-full'
        />
        <button type='button' className='absolute inset-y-0 left-0 flex items-center pl-3'>
          <Icon icon="ri:search-line" width="24" height="24" className='text-slate-500' />
        </button>
      </div>
    </div>

    {/* Scrollable content */}
    <div className="flex-grow overflow-y-auto">
      <div className="flex px-[5%] py-5 bg-slate-50 text-slate-600 font-semibold">
        <div className="lg:w-1/4 w-2/5 text-xs lg:text-sm font-semibold">Project Name</div>
        <div className="lg:w-1/5 w-[30%] text-xs lg:text-sm font-semibold">Date</div>
        <div className="w-1/5 hidden lg:block text-xs lg:text-sm font-semibold">Status</div>
        <div className="lg:w-auto w-[30%] text-xs lg:text-sm font-semibold">Created By</div>
      </div>

      {/* Rows */}
      <div className="flex px-[5%] items-center py-3">
        <div className="lg:w-1/4 w-2/5 text-xs font-semibold">Red Stone Builder</div>
        <div className="lg:w-1/5 w-[30%] text-xs font-semibold">24 Oct 2024</div>
        <div className="w-1/5 hidden lg:block">
          <p className='font-semibold text-xs w-fit py-1 px-3 border-2 bg-green-50 border-green-500 text-green-500 rounded-lg'>
            Successful
          </p>
        </div>
        <div className="lg:w-auto w-[30%] flex items-center gap-2">
          <img src={handImg} className='w-10 h-10 rounded-full' alt="profileImg" />
          <p className='font-semibold text-xs hidden lg:block'>Muhammad Ahmad</p>
        </div>
      </div>
      <div className="flex px-[5%] items-center py-3">
        <div className="lg:w-1/4 w-2/5 text-xs font-semibold">Red Stone Builder</div>
        <div className="lg:w-1/5 w-[30%] text-xs font-semibold">24 Oct 2024</div>
        <div className="w-1/5 hidden lg:block">
          <p className='font-semibold text-xs w-fit py-1 px-3 border-2 bg-green-50 border-green-500 text-green-500 rounded-lg'>
            Successful
          </p>
        </div>
        <div className="lg:w-auto w-[30%] flex items-center gap-2">
          <img src={handImg} className='w-10 h-10 rounded-full' alt="profileImg" />
          <p className='font-semibold text-xs hidden lg:block'>Muhammad Ahmad</p>
        </div>
      </div>
      <div className="flex px-[5%] items-center py-3">
        <div className="lg:w-1/4 w-2/5 text-xs font-semibold">Red Stone Builder</div>
        <div className="lg:w-1/5 w-[30%] text-xs font-semibold">24 Oct 2024</div>
        <div className="w-1/5 hidden lg:block">
          <p className='font-semibold text-xs w-fit py-1 px-3 border-2 bg-green-50 border-green-500 text-green-500 rounded-lg'>
            Successful
          </p>
        </div>
        <div className="lg:w-auto w-[30%] flex items-center gap-2">
          <img src={handImg} className='w-10 h-10 rounded-full' alt="profileImg" />
          <p className='font-semibold text-xs hidden lg:block'>Muhammad Ahmad</p>
        </div>
      </div>
      <div className="flex px-[5%] items-center py-3">
        <div className="lg:w-1/4 w-2/5 text-xs font-semibold">Red Stone Builder</div>
        <div className="lg:w-1/5 w-[30%] text-xs font-semibold">24 Oct 2024</div>
        <div className="w-1/5 hidden lg:block">
          <p className='font-semibold text-xs w-fit py-1 px-3 border-2 bg-green-50 border-green-500 text-green-500 rounded-lg'>
            Successful
          </p>
        </div>
        <div className="lg:w-auto w-[30%] flex items-center gap-2">
          <img src={handImg} className='w-10 h-10 rounded-full' alt="profileImg" />
          <p className='font-semibold text-xs hidden lg:block'>Muhammad Ahmad</p>
        </div>
      </div>
      <div className="flex px-[5%] items-center py-3">
        <div className="lg:w-1/4 w-2/5 text-xs font-semibold">Red Stone Builder</div>
        <div className="lg:w-1/5 w-[30%] text-xs font-semibold">24 Oct 2024</div>
        <div className="w-1/5 hidden lg:block">
          <p className='font-semibold text-xs w-fit py-1 px-3 border-2 bg-green-50 border-green-500 text-green-500 rounded-lg'>
            Successful
          </p>
        </div>
        <div className="lg:w-auto w-[30%] flex items-center gap-2">
          <img src={handImg} className='w-10 h-10 rounded-full' alt="profileImg" />
          <p className='font-semibold text-xs hidden lg:block'>Muhammad Ahmad</p>
        </div>
      </div>
      <div className="flex px-[5%] items-center py-3">
        <div className="lg:w-1/4 w-2/5 text-xs font-semibold">Red Stone Builder</div>
        <div className="lg:w-1/5 w-[30%] text-xs font-semibold">24 Oct 2024</div>
        <div className="w-1/5 hidden lg:block">
          <p className='font-semibold text-xs w-fit py-1 px-3 border-2 bg-green-50 border-green-500 text-green-500 rounded-lg'>
            Successful
          </p>
        </div>
        <div className="lg:w-auto w-[30%] flex items-center gap-2">
          <img src={handImg} className='w-10 h-10 rounded-full' alt="profileImg" />
          <p className='font-semibold text-xs hidden lg:block'>Muhammad Ahmad</p>
        </div>
      </div>

      {/* Add more rows as needed */}
    </div>
  </div>
</div>


        </div>
        </div>
      </div>
    </div>
  )
}

export default ChartPage
